import React, { useState, useEffect } from "react";
import './terms-and-conditions-styles.css';
import SecondaryHeader from "../../Components/SecondaryHeader.js";
import HOPELogo from '../../Assets/Logos/HOPE Logo.png';
import HOPEBuddyLogo from '../../Assets/Logos/HOPE Buddy Logo.png';
import HopeTermsAndConditions from "../../Components/HopeTermsAndConditions/HopeTermsAndConditions.jsx";
import HopeBuddyTermsAndConditions from "../../Components/HopeBuddyTermsAndConditions/HopeBuddyTermsAndConditions.jsx";

const TermsAndConditions = () => {

    const [isHopeActive, setIsHopeActive] = useState(true);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
        const hopebuddy = urlParams.get('hopebuddy');
        if (hopebuddy) {
            setIsHopeActive(false);
        }
    }, []);

    return (
        <div>
            <SecondaryHeader />
            <div className="logo-container1">
                <div onClick={() => setIsHopeActive(true)} className={isHopeActive ? "logoo-container cur-point" : "cur-point"}>
                    <img className={isHopeActive ? "logo-btn1-active" : "logo-btn1-inactive"} src={HOPELogo} />
                </div>
                <div onClick={() => setIsHopeActive(false)} className={isHopeActive ? "cur-point" : "logoo-container1 cur-point"}>
                    <img className={isHopeActive ? "logo-btn1-inactive" : "logo-btn1-active"} src={HOPEBuddyLogo} />
                </div>
            </div>
            <div className="pdf-container">
                {
                    isHopeActive ?
                        <HopeTermsAndConditions />
                        :
                        <HopeBuddyTermsAndConditions />
                }
            </div>
        </div>
    );
}

export default TermsAndConditions;