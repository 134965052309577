import React from 'react';
import '../HopeTermsAndConditions/HopeTermsAndConditions.css';
import { Margin } from '@mui/icons-material';

const HopeTermsAndConditions = () => {
    return (
        <div className={`hope-privacy-policy-main-container`}>
            <div className={`title-container`}>
                <p className={`title`}>TERMS AND CONDITIONS</p>
            </div>
            <p className='para bold'>
                THIS IS A LEGAL AGREEMENT OF THE TERMS OF USE BETWEEN THE INDIVIDUAL OR
                LEGAL ENTITY OR ASSOCIATION (HEREINAFTER REFERRED TO AS YOU OR YOUR OR
                USER AND WHICH EXPRESSION SHALL MEAN AND INCLUDES PATIENTS AND
                CAREGIVERS [AS DEFINED HEREIN BELOW] ) INTENDING TO USE THE PLATFORM (AS
                DEFINED HEREINBELOW) AND SOLUTIZE HEALTHCARE PRIVATE LIMITED
                (HEREINAFTER REFERRED TO AS WE, US, OUR, OR COMPANY AND WHICH
                EXPRESSION SHALL MEAN AND INCLUDES AFFILIATES, SUCCESSORS AND
                PERMITTED ASSIGNS). BY REGISTERING FOR THE SERVICES, BROWSING OR BY
                USING THE SERVICES OF THE PLATFORM YOU UNCONDITIONALLY AND
                IRREVOCABLY REPRESENT, WARRANT, AND AGREE THAT YOU HAVE READ,
                UNDERSTOOD, AND AGREE TO BE BOUND TO THESE TERMS. IF YOU DO NOT AGREE
                TO BE BOUND BY THESE TERMS, OR YOU DO NOT HAVE THE AUTHORITY TO BIND TO
                THE AGREEMENT, YOU MAY NOT USE THE SERVICES OR ACCESS THE PLATFORM.
                OUR PRIVACY POLICY CAN BE FOUND HERE WWW.HOPETHEAPP.COM. THESE
                TERMS AND OUR PRIVACY POLICY CONSTITUTE A BINDING CONTRACT ON YOU AND
                GOVERNS YOUR USE OF AND ACCESS TO THE SERVICES OR PLATFORM BY YOU,
                YOUR AGENTS, AND USER. PLEASE READ IT CAREFULLY.
            </p>
            <p className="para">
                <span className='bold'>
                    This document is published in accordance with the provisions of Rule 3 (1) of the Information
                    Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and
                    regulations, privacy policy and Terms of Use for access or usage of the
                </span> <a href="www.hopetheapp.com">www.hopetheapp.com</a> and HOPE AAP
            </p>
            <p className="para">
                <a href="www.hopetheapp.com">www.hopetheapp.com</a> and the mobile application ‘HOPE’ (together referred to as
                <span className='bold'> “PLATFORM”</span>) (hope buddy is not mentioned) is owned and operated by <span className='bold'>SOLUTIZE HEALTHCARE
                    PRIVATE LIMITED</span> having its registered office currently located at C-4504 DB Woods, Krishna
                Vatika Marg, Gokuldham, Goregaon East, Mumbai, Maharashtra, India, 400063
            </p>
            <p className="para">
                HOPE App is an integrated digital healthcare platform that aims to fulfil the disease/treatment
                management and home care needs of patients and to facilitate the connection between patients,
                their Care givers, and a variety of medical or health service providers. It aims to start with home-based nursing as the core service provided by the app, specifically for Cancer patients in Mumbai
                and surrounding regions. (“<span className='bold'>Said Purpose</span>”)
            </p>
            <p className='para'>
                <ol>
                    <li className='heading'>
                        DEFINITIONS
                    </li>
                    <ol className='abcd'>

                        <p className="para">
                            For the purposes of this Agreement, in addition to what is stated herein, the following terms shall
                            have the meaning as set forth below unless otherwise specified herein:
                        </p>
                        <li>
                            <span className='bold'> Platform:</span> The platform includes www.hopetheapp.com and the mobile application
                            ‘HOPE’. It also includes browser extensions, the queries answered by the Hope team over
                            email, phone or another medium.
                        </li>
                        <li>
                            <span className='bold'> User:</span> The term user includes person/institutions browsing the website or registered on
                            the app, a trial user (if any), end-user (subscription-based) and everyone who maybe
                            using the platform and/or availing services duly defined herein. This includes, but not
                            limited to, patients, caregivers, and Medical/Health Service Providers.
                        </li>
                        <li>
                            <span className='bold'> Medical or health service provider(Provider) :</span> The term medical or health service
                            provider includes whether an individual professional or an organization or similar
                            institution wishing to be listed, or already listed, on the panel of the company, including
                            designated, authorized associates of such providers or institutions including but not
                            limited to nursing professionals or health care professionals.
                        </li>
                        <li>
                            <span className='bold'> Patient:</span> Patient means any individual who is willing to register (trial user) or is already
                            registered (end user) on the platform , by following all procedures which shall include
                            sharing personal and medical information ; consents etc. , for the purpose of managing
                            their own health and desirous of availing services offered by the platform as defined
                            herein.
                        </li>
                        <li>
                            <span className='bold'> Caregiver:</span> Care giver means persons who are authorised representatives of patient who
                            will be managing membership and treatment on his/her behalf which shall include
                            family/relatives, close friends, neighbors.
                        </li>
                        <p className="para">
                            <span className='bold'>Service: </span>
                            We enable users to connect with Medical or health service provider for their
                            medical/healthcare requirements through this platform by using our proprietary
                            algorithm where user can have access and connect to various medical or health service
                            provider. This also includes home-based nursing as the core service provided by the app,
                            specifically for Cancer patients, in Mumbai and surrounding regions and/or any other kind
                            of services provided through the platform including (I) Plan and track their treatment
                            effectively, (ii) Stay connected with caregivers and medical service providers and
                            coordinate activities with them, (iii) Book appointments (in person or virtual)v,(iv) Hire
                            care professionals for home-based services, (v) Get reminders for important activities,
                            (vi) Digital documentation of doctor visits, prescriptions, lab reports, etc., (vii) Connect
                            with other patients having similar issues,(viii) Article section for building patient
                            awareness and empowerment via HOPE APP.
                        </p>
                    </ol>
                    <li className='heading'>
                        APPLICABLE LAWS
                    </li>
                    <p className='para'>
                        This Platform is created and controlled by the Company in India; as such the laws of India shall
                        apply; courts in Mumbai, India shall alone have exclusive jurisdiction (subject to Arbitration at
                        Mumbai, India) in respect of all the terms, conditions, and disclaimers. The Company exclusively
                        reserves the right to make the changes to the site and the terms, conditions, Privacy Policy, and
                        3
                        disclaimers thereof. The User is deemed to have been entered into at India and the laws of India
                        will govern this Agreement. Any dispute, claim or controversy arising out of or relating to this
                        Agreement, including the determination of the scope or applicability of this Agreement to
                        arbitrate, or your use of the platform or the Services or information to which it gives access, as
                        also including interpretation of any of the terms shall be referred to Arbitration in Mumbai, India
                        under the Arbitration and Conciliation Act (Indian Act), 1996 before a Sole Arbitrator. The seat
                        of such arbitration shall be Mumbai. All proceedings of such arbitration, including, without
                        limitation, any awards, shall be in the English language. The award shall be final and binding on
                        the parties to the dispute.
                    </p>
                    <li className='heading'>
                        ELIGIBILITY
                    </li>
                    <p className='para'>
                        You represent and warrant that you are competent and eligible to enter into a legally binding
                        agreement and have the requisite authority to bind the other party to this Agreement. You shall
                        not use this Service if you are not competent to contract under the applicable Indian Laws, rules,
                        and regulations. By registering on Platform, you explicitly understand and agree that;
                    </p>
                    <ul>
                        <li>
                            The minimum age for registering is 18 years.
                        </li>
                        <li>
                            You are not disabled by any law from entering into a contract.
                        </li>
                        <li>
                            You have gone through the Terms and Conditions and agree to be bound to them.
                        </li>
                        <li>
                            Person under 18 years of age may access and use the services through parent or guardian
                            If you wish to connect to the Medical or health service provider and makes use of the
                            Platform, read these Terms of Use and follow the instructions in the registration process.
                            By using the Platform, you consent to submit your personal identifiable information, to
                            collect, process and display and use the said information to access the Platform.
                        </li>
                    </ul>
                    <li className='heading'>
                        GENERAL TERMS
                    </li>
                    <ol >

                        <li className='para'>
                            This Agreement will be effective, valid, and subsisting from the date you use our Platform.
                            The Agreement applies to person/institutions browsing the website or registered on the app,
                            a trial user (if any), end-user (subscription-based) and everyone who maybe using the
                            platform and/or availing services duly defined herein. This includes, but not limited to,
                            patients, caregivers, and Medical/Health Service Providers respectively defined herein.
                        </li>
                        <li className='para'>
                            The present Agreement provides for the terms and conditions under which you are allowed
                            to use the Platform and describes the manner in which we shall treat your account for the
                            registered User or trail user. In case of any query in respect of any part of the Agreement, user
                            can connect to us at <span style={{ textDecoration: "underline" }}>support@hopetheapp.com</span>
                        </li>
                        <li className='para'>
                            By downloading or accessing the Platform for the purpose to use the Services, you irrevocably
                            accept all the conditions stipulated in this Agreement, the Subscription Terms of
                            Service and Privacy Policy, as available on the Platform , and agree to abide by them. Unless a
                            mutually executed agreement between you and us (if any) states otherwise, the present
                            Agreement supersedes all prior oral and written terms and conditions (if any) communicated to
                            you relating to your use of the Platform to avail the Services. By availing any Service, you signify
                            your acceptance of the terms of this Agreement.
                        </li>
                        <li className='para'>
                            We shall have sole discretionary power to
                            change the Service and may change the service from time to time, and the present Agreement
                            will be applicable to your access to and your use of the Platform for the purpose of availing the
                            Service, as well as to all information provided by you on the Platform at any given point in time.
                        </li>
                        <li className='para'>
                            We reserve the right to modify, revoke or amend or update any Terms of the present
                            Agreement for any reason and at any time, and such modifications shall be notified to you
                            through communication method on the portal as an alert on the platform. You should read the
                            Agreement at regular intervals. Your use of the Platform following any such modification
                            constitutes your agreement to follow and be bound by the Agreement so modified.
                        </li>
                        <li className='para'>
                            You acknowledge that you will be bound by this Agreement for availing any of the Services
                            offered by us. If you do not agree with any part of the Agreement, please do not use the
                            Platform or avail any Services.
                        </li>
                        <li className='para'>
                            Your access to use of the Platform and the Services will be solely at the discretion of the
                            Company.
                        </li>
                        <li className='para'>
                            The Agreement is published in compliance of, and is governed by the provisions of Indian
                            law, including but not limited to:
                            <ol className='roman'>
                                <li>
                                    the Indian Contract Act, 1872
                                </li>
                                <li>
                                    the (Indian) Information Technology Act, 2000, and
                                </li>
                                <li>
                                    the rules, regulations, guidelines, and clarifications framed there under, including
                                    the (Indian) Information Technology (Reasonable Security Practices and
                                    Procedures and Sensitive Personal Information) Rules, 2011 (the <span className='bold'>“SPDI
                                        Rules”</span> ), and the (Indian) Information Technology (Intermediaries Guidelines)
                                    Rules, 2011 (the <span className='bold'>“IG Rules”</span>).
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <li className='heading'>
                        TERMS OF USE APPLICABLE TO ALL USERS OTHER THAN MEDICAL OR HEALTHCARE PROVIDERS
                    </li>
                    <p className='para'>
                        The terms in this Clause 5 are applicable only to Users other than Medical or
                        health service provider.
                    </p>
                    <p className='para'>
                        5.1.USER ACCOUNT AND DATA PRIVACY
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.1</span> The terms “personal information” and “sensitive personal data or information”
                        are defined under the SPDI Rules and are reproduced in the Privacy Policy.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.2</span> You acknowledge that we may collect information about the devices you use
                        to access the platform and anonymous usage data solely for the purpose of enhancing
                        the quality of the provided Service and developing new services.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.3</span>  It is understood by You that, for the purpose of communication and with the
                        intent to provide its User a convenience to schedule appointments and to received
                        feedback in respect to the Medical or health service provider and their service, The
                        user allows us to have access to User’s personal contact details such as email or
                        phone number,.

                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.4</span> The Privacy Policy sets out, inter-alia:
                        <ol className='roman'>
                            <li>
                                The kind of information gathered from Users, inclusive of sensitive
                                personal data or information;
                            </li>
                            <li>
                                The purpose, means and modes of usage of such information;

                            </li>
                            <li>
                                How and to whom HOPE will disclose such information; and,
                            </li>
                            <li>
                                Other information mandated by the SPDI Rules.
                            </li>
                        </ol>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.5</span> The User is expected to read and understand the Privacy Policy, to ensure that
                        he or she has the knowledge of inter-alia:
                        <ol className='roman'>
                            <li>
                                the fact that certain information is being collected;
                            </li>
                            <li>
                                the purpose behind the gathering of information;
                            </li>
                            <li>
                                the intended recipients of the information;
                            </li>
                            <li>
                                the nature of gathering and retention of the information; and
                            </li>
                            <li>
                                the name and address of the agency that is collecting the information and
                                the agency that will retain the information; and
                            </li>
                            <li>
                                The various rights available to such Users in respect of such information.
                            </li>
                        </ol>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.6</span> We shall not be liable in any manner for the authenticity of the personal
                        information or sensitive personal data, or information provided by the User
                        to us or to any other person acting on behalf of us.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.7</span> It shall be the sole responsibility of the User of the Platform to maintain
                        the confidentiality of the User’s account access information irrespective of
                        the same is authorized by the User. In case of any actual or suspected
                        unauthorized use of the User’s Account, the User shall immediately intimate
                        us. Although we shall not be liable for your losses caused by any unauthorized
                        use of your account but shall cooperate to get the access without any
                        commitment, nevertheless User shall be liable for the losses/Damages caused
                        to us or such other parties as the case may be, due to any unauthorized use
                        of your account.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.8</span> We shall at its sole discretion is entitled to terminate/discontinue the
                        Services to the User or suspend the Users Account in the event If any
                        information provided by a User is untrue, inaccurate, not current or
                        incomplete (or becomes untrue, inaccurate, not current or incomplete), or
                        HOPE has reasonable grounds to suspect that such information is untrue,
                        inaccurate, not current or incomplete.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>5.1.9</span> We may use such information gathered from the Users from time to
                        time for the purposes of debugging customer support related issues.
                    </p>
                    <li className='heading'>
                        APPOINTMENT SCHEDULING FACILITY:
                    </li>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.1</span> We enable Users to connect with Providers for medical service and to hire Professionals
                        for home-based service through numbers displayed on the Platform.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.2</span> We will ensure Users are provided confirmed appointment on the Appointment
                        Scheduling facility. However, we shall not be liable for the non-availability of the
                        Provider or if the Provider subsequently cancelled appointment or
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.3</span> If a User has availed the telephonic services, we reserves the right to share the
                        information given by the User with the Provider and store such information and/or
                        conversation of the User with the Provider, in accordance with our Privacy Policy.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.4 We do not endorse any such Particular a Service Provider which are</span>  appear <span className='bold'>as</span> the
                        results of search Users perform on the Platform for Providers any such particular
                        Provider. The user understood that, if the User decides to engage with a Provider to
                        seek medical services, the User shall be doing so at his/her own cost and risk.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.5</span> Without prejudice to the generality of the above, we are not involved in providing any
                        healthcare or medical advice or diagnosis and hence shall not be liable for any
                        Communication between User and the Provider. User understands and agrees that we
                        will not be liable for:
                        <ol className='roman'>
                            <li>
                                User communication and associated issues User has with the
                                Provider;
                            </li>
                            <li>
                                the ability or intent of the Provider(s) or the lack of it, in fulfilling
                                their obligations towards Users ;
                            </li>
                            <li>
                                any wrong medication or quality of treatment being given by the
                                Provider(s), or any medical negligence on part of the Provider(s);
                            </li>
                            <li>
                                inappropriate treatment, or similar difficulties or any type of
                                inconvenience suffered by the User due to a failure on the part of
                                the Provider to provide agreed Services;
                            </li>
                            <li>
                                any misconduct or inappropriate behaviour by the Provider or the
                                Provider’s staff;
                            </li>
                            <li>
                                Cancellation or non-availability of the Provider or rescheduling of
                                booked appointment or if any variation in the fees charged.
                            </li>
                        </ol>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.6 </span>  Users are permitted to provide feedback about their experiences with the Provider,
                        however, the User shall ensure that, the same is provided in accordance with applicable
                        law. Further, User however understands that we shall not be obliged to act in such
                        manner as may be required to give effect to the Articles of Users feedback, such as
                        suggestions for delisting of a particular Provider from panel.
                    </p>
                    <p className='para' style={{ marginLeft: "25px", fontWeight: "bold" }}>
                        <span className='bold'>6.7 </span>  <span style={{ textDecoration: "underline" }}>CANCELLATION AND REFUND POLICY
                        </span>
                        <p className='para'>
                            a&#93; In the event, Subsequent to the booking of Appointment with a Provider through the
                            Platform and the user cancels the appointment then in that case user shall be entitle
                            to the following:
                            <ul>
                                <li className='para'>
                                    In the event, Subsequent to the booking of Appointment with a Provider through the
                                    Platform and the user cancels the appointment then in that case user shall be entitle
                                    to the following:
                                </li>
                                <li className='para'>
                                    If a patient cancels within 24 hours of the appointment, patient will not receive any
                                    refund.
                                </li>
                            </ul>
                        </p>

                        <p className='para'>
                            b&#93; If Provider/we cancels the appointment Subsequent to the booking of Appointment with a
                            Provider through the Platform, then in that case user shall be entitled to receive a full
                            refund of the consultation fee within 7-10 business days.
                        </p>
                    </p>
                    <p className='para'>
                        <span className='bold'>6.8</span> <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                            Articles
                        </span>
                    </p>
                    <p className='para'>
                        These terms & conditions governing Articles are applicable to Users. However, it is clarified
                        that the terms and conditions herein applicable only to Providers and applicable to Users
                        are called out separately, as the context warrants.
                    </p>
                    <p className='para'>
                        <span className='bold'>The Platform provides for an</span> online Articles platform known as HOPE Article, for the
                        Providers who have created a HOPE profile can login and post health and wellness related
                        Articles.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.1 </span>  Providers can use “Articles” feature by logging into the platform, creating <span className='bold'>original </span>
                        Articles comprising text, audio, video, images, data, or any combination of the
                        same <span className='bold'>(“Articles”) </span> and uploading said Articles to our servers. User acknowledges that
                        the User will use the content from the HOPE Articles at their own risk and we do not
                        provide any warranty as to the ownership of the intellectual property in the HOPE
                        Articles. The provider shall always oblige to post content subject to these Terms and
                        Conditions. Any content uploaded via HOPE Articles does not constitute medical
                        advice and may not be construed as such by any person.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.2 </span> Providers acknowledges that they are the original authors and creators of any
                        content uploaded by them via Articles and that none of their submissions would
                        violate anyone else's intellectual property rights , in case Provider is not original
                        Author of the article then in that case he have to ensure he have necessary license
                        or right to reproduce the article along with credits to the original author awe reserves
                        the right to delete any Articles that it decides in its own discretion, violates the
                        intellectual property rights of any other person, including but not limited to patent,
                        trademark, copyright or other proprietary rights . Provider agrees to release
                        Company from and indemnify us against all claims that may arise as a result of any
                        third-party intellectual property right claim that may arise from the Provider’s
                        uploading of any Articles on the HOPE Articles. The content in the HOPE Articles may
                        not be used by the Provider for the any purpose other than those directly related to
                        the creation and uploading of content to HOPE Articles. The Provider also agrees to
                        release HOPE from and indemnify HOPE against all claims, cost, damages, losses that
                        may arise as a result of any third-party intellectual property claim if the Provider
                        downloads, copies or otherwise utilizes an image from the HOPE Articles for his/her
                        personal or commercial gain.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.3 </span> Provider hereby assigns, all intellectual property rights in any content created by
                        the User and uploaded by the User via Articles to Company in perpetuity and
                        throughout the world.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.4 </span> We reserve the right to delete the content and any comments at any time in a way
                        that it deems appropriate for Articles.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.5 </span> Provider shall ensure that the content or any further responses to the content
                        (including responses to Users ) is not harmful, harassing, blasphemous, defamatory,
                        obscene, pornographic, paedophilic, or libellous in any manner. Further, Provider
                        should ensure that the content is not invasive of any other person’s privacy, or
                        otherwise contains any elements that is hateful, racially, or ethnically objectionable,
                        disparaging, or otherwise unlawful in any manner whatever. We reserve the right to
                        delete any content that it decides in its own discretion is violates of the Terms and
                        Conditions or any law or statute in force at the time. Also, the Provider agrees to
                        release Company from and indemnify us against all claims , cost, damages, losses that
                        may arise as a result of any legal claim arising from the nature of the content posted
                        by the Provider on HOPE Articles.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.6 </span> Provider shall ensure that any part of the content should not violate any law for the
                        time being in force.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.7 </span> Provider shall ensure that the content is not threatening the unity, integrity, defence,
                        security or sovereignty of India, friendly relations with foreign states, or public order.
                        Further the Provider shall ensure that the content will not cause incitement to the
                        commission of any cognisable offence or prevent investigation of any offence or is
                        insulting to any other nation.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.8 </span> User may also use HOPE Articles in order to view original content created by
                        Providers and to create and post comments on such material, where allowed <span className='bold'>(“User
                            Comment”). </span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.9 </span> User acknowledges that the User Comment reflects the views and opinions of the
                        authors of such content and do not necessarily reflect the views of HOPE.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.10 </span> User agrees that the content they access on HOPE Articles does not in any way
                        constitute medical advice and that the responsibility for any act or omission by the
                        User arising from the User’s interpretation of the material, is solely attributable to
                        the User. The User agrees to release HOPE from and indemnify HOPE against all
                        claims that may arise as a result of the User’s actions resulting from the User’s
                        viewing of content on HOPE Articles.

                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.11 </span> The User acknowledges and agrees not to infringe upon our intellectual property by
                        copying or plagiarizing Articles on HOPE Articles. We reserve its right to initiate all
                        appropriate legal remedies available to them in case of such an infringement by the
                        User. The User agrees to refraining from posting User Comment that caused the
                        violation of the intellectual property including but not limited to patent, trademark,
                        copyright or other proprietary rights of any third party, HOPE reserves the right to
                        delete any User Comment that it decides, in its own discretion as violating the
                        intellectual property rights of any third party. The User agrees to release us from and
                        indemnify us against all claims that may arise as a result of any third-party intellectual
                        property right claim that may arise from the User Comment.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.8.12 </span> User shall ensure that the User Comment is not threatening the unity, integrity,
                        defence, security or sovereignty of India, friendly relations with foreign states, or
                        public order. Further the Provider shall ensure that the User Comment will not cause
                        incitement to the commission of any cognisable offence or prevent investigation of
                        any offence or is insulting to any other nation.
                    </p>
                    <p className='para'>
                        <span className='bold'>6.9 </span> <span style={{ textDecoration: "underline", fontWeight: "bold" }}>HEALTH RECORD AND PAPERLESS DOCUMENTATION</span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>A free feature called "Paperless Documentation" i.e., Digital records and documents in
                        respect of the Service availed, and Health Record of the User may be made available by us
                        to End-Users on Said platform. There are two types of information/records are contained
                        in your Paperless Documentation.</p>
                    <ol className='roman'>
                        <li>
                            User-created: Any Information uploaded by you, or information generated during your
                            interaction with HOPE ecosystem, ex: appointment, medicine order placed by you, Lab
                            reports.
                        </li>
                        <li>
                            Practice/Provider-created: Health Records generated by your interaction with a Provider,
                            any prescription/ health related reports, bills etc.
                        </li>
                    </ol>
                    <p className='para'>
                        The specific terms relating to such Health Account are as below, without prejudice to the
                        rest of these Terms and the Privacy Policy:
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.1 </span> Your Paperless Documentation is only created after you have signed up and explicitly
                        accepted these Terms
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.2 </span> Any Practice/Provider created Health Record is provided on an as-is basis at the sole
                        intent, risk and responsibility of the Provider and we do neither validate the said
                        information and nor makes any such representation in connection therewith. If there
                        are any inconsistencies or if you want to add, delete, or change anything in the Health
                        Record in any manner, you should get in touch with the appropriate Provider.

                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.3 </span> The Health Records are provided on an as-is basis. While we strive to maintain the
                        highest levels of service availability, we are not liable for any interruption that may
                        be caused to your access of the Services.

                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.4 </span> Your Health records as well as Paperless Documents are protected with the
                        industry–level security and encryption used by us. However, in the event, you forgot
                        or lose your login credentials then in that event we do not guarantee to prevent
                        unauthorized access, or they are otherwise compromised. You are aware you shall
                        immediately inform us of such unauthorized use or access, of any unauthorized use
                        or access, you please keep your login credentials safe and notify at
                        support@hopetheapp.com of any actual suspected breach of account
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.5 </span> you access your dependents’ Health Records by registering your dependents with
                        your own Records, you are deemed to be liable for the Health Records of your
                        dependents and all obligations that your dependents would have had, had they
                        maintained their own separate individual Records. You agree that you are solely
                        liable to get prior consent of your dependent and shall have right to share, upload
                        and publish any sensitive personal information of your dependent. We shall not be
                        liable for any claim, dispute or liability arising in this regard, and you shall indemnify
                        us and its officers against any such claim or liability arising out of unauthorized use
                        of such information.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.6 </span> You hereby confirm, declare, and grant your consent to the fact that once health
                        records updated/uploaded cannot be deleted.

                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.7 </span> You should always sync your “User created” record with the server otherwise you
                        may lose the same and We Shall not be liable for any such loss.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.8 </span> We are not liable or under the obligation for any content, fact, Health Records and
                        Paperless Documents , medical deduction or the language used in your Health
                        Records and/or Paperless Documents whatsoever. Your Provider is solely liable and
                        under the obligation for your Health Records and any information provided to us
                        including but not limited to the Articles in them.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.9 </span> If it is found that Health Records and Paperless Documents shared incorrectly or
                        inadvertently then we will be able in our sole discretion to retract Health Records
                        without any prior notice.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.10 </span> We will abide by the law of land in case of any constitutional court or jurisdiction
                        mandates to share the Health Records and Paperless Documents for any reason.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.11 </span> You agree and acknowledge that we may need to access the Health Record and
                        Paperless Documents for cases such as any technical or operational issue of the End
                        User in access or ownership of the Records.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.12 </span> You acknowledge that the Providers you are visiting may engage HOPE's software or
                        third-party software for the purposes of the functioning of the Provider’s business
                        and HOPE's services including but not limited to the usage and for storage of Records
                        and Paperless Documents in India and outside India, as per the applicable laws.

                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.9.13 </span> By visiting or using HOPE products, you authorize us to store your Records and
                        Paperless Documents shared with providers. This consent extends to affiliated clinics
                        and hospitals, encompassing the use and storage of your data, both in India and
                        internationally, while complying with relevant laws. You also agree to associate these
                        documents with your User account upon registration.
                    </p>
                    <p className='para'>
                        <span className='bold'>6.10 </span> <span style={{ fontWeight: "bold", textDecoration: "underline" }} >HOPE CONNECT:</span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        We provide form a free feature called HOPE CONNECT which aim to connect the Users with each
                        other privately by sending request and upon acceptance of request users have an opportunity to
                        interact and share their experiences with each other Privately. The termsfor using HOPE CONNET
                        are as follows:
                    </p>
                    <ol className='roman'>
                        <li>
                            User shall not use the HOPE CONNECT Platform for any other Purpose other than
                            connecting with the other patients.
                        </li>
                        <li>
                            We shall not be liable under any circumstance for the conversation including but not
                            limited to derogatory language, objectionable, pornographic and /or offensive
                            Articles between users Using HOPE CONNECT feature. Further shall always keep us
                            indemnified against all claims, cost , damages, and losses that may arise on account
                            of User using HOPE CONNECT feature ;

                        </li>
                    </ol>
                    <p className='para'>
                        <span className='bold'>6.11 </span> <span style={{ fontWeight: "bold", textDecoration: "underline" }} >PROFILE OWNERSHIP AND EDITING RIGHTS:</span>
                    </p>
                    <p className='para'>
                        We offer a mechanism to update your profile information, ensuring quick access to the
                        Providers. HOPE reserves the right of ownership of all the Provider’s profile and
                        photographs and to moderate the changes or updates requested by Providers. However,
                        we take the independent decision whether to publish or reject the requests submitted for
                        the respective changes or updates. You hereby represent and warrant that you are fully
                        authorised under law to upload all content uploaded by you as part of your profile or
                        otherwise while using HOPE’s services, and that no such content violates any third-party
                        rights, including intellectual property rights. We may modify or delete parts of your profile
                        information at its sole discretion with or without notice to you if any breach of the
                        foregoing representation on your part is found by the us.
                    </p>
                    <p className='para'>
                        <span className='bold'>6.12 </span> <span style={{ fontWeight: "bold", textDecoration: "underline" }} >REVIEWS AND FEEDBACK DISPLAY RIGHTS OF HOPE:</span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.12.1 </span> All the Critical content (content that you choose to submit for publication on the
                        platform, including any feedback, ratings, or reviews) is content created by the Users
                        of platform and the clients of HOPE customers and Providers, including the End-Users. As a platform, HOPE does not take responsibility for Critical Articles and its
                        role with respect to Critical Articles is restricted to that of an ‘intermediary’ under
                        the Information Technology Act, 2000. T
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.12.2 </span> We reserve the right to collect feedback and Critical content for all the Providers,
                        Clinics and Healthcare Providers listed on the APP
                    </p><p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.12.3 </span>We shall have no obligation to pre-screen, review, flag, filter, modify, refuse, or
                        delete any or all content from any Service, except as required by applicable law.

                    </p><p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.12.4 </span>You understand that we shall not be liable for any effect on Provider’s business due
                        to any content or other content which may find as offensive or objectionable and
                        which you may be exposed. While using the Services. You too content that you may
                        find offensive or objectionable. In these respects, you may use the Service at your
                        own risk. HOPE however, as an ‘intermediary, takes steps as required to comply with
                        applicable law as regards the publication of Critical content. T
                    </p><p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.12.5 </span> We will remove any such information under standards consistent with applicable law
                        and shall in no circumstances be liable and under obligation for Critical content,
                        which has been created by the Users. The principles set out in relation to third party
                        content in the terms of Service for the APP shall be applicable <span style={{ fontWeight: "bold" }}> mutatis mutandis </span>  in
                        relation to Critical content posted on the APP
                    </p><p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>6.12.6 </span> In the circumstances, If we determine that you have provided false and incorrect
                        information or enabled fraudulent feedback, We reserve the right to immediately
                        suspend any of your accounts with us and makes such declaration on the APP
                        alongside your name/your clinics name as determined by us for the protection of its
                        business and in the interests of Users.
                    </p>
                    <p className='para'>
                        <span className='bold'>6.13 </span> <span style={{ fontWeight: "bold", textDecoration: "underline" }} >RELEVANCE ALGORITHM</span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        The relevance algorithm is created by HOPE for the best interest of the End-User and May
                        modified the relevance algorithm from time to time to enhances the quality of the results given
                        to the patients. It is a pure merit based, proprietary algorithm and cannot be altered for specific
                        Providers. HOPE shall not be liable for any effect on the Provider’s business interests due to the
                        modification in the Relevance Algorithm.
                    </p>
                    <p className='para'>
                        <span className='bold'>6.14 </span> <span style={{ fontWeight: "bold", textDecoration: "underline" }} >INDEPENDENT SERVICES</span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        Your rights and obligations are limited to the service availed by you and not to any other service
                        that may be provided by HOPE.
                    </p>
                    <p className='para'>
                        <span className='bold'>6.15 </span> <span style={{ fontWeight: "bold", textDecoration: "underline" }} >HOPE REACH RIGHTS</span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        We reserve the rights to display sponsored ads on the platform. These ads would be marked as
                        “Sponsored ads.” Without prejudice to the status of other content, HOPE shall not be liable for
                        the accuracy of information, or the claims made in the Sponsored ads. we do not encourage
                        the Users to visit the Sponsored ads page or to avail any services from them. we shall not be
                        liable for the services of the providers of the Sponsored ad
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        You represent and warrant that you will use these Services in accordance with applicable law. You
                        are solely responsible if you use this service in contravention of applicable law and we shall
                        not be liable for the same
                    </p>
                    <p className='para'>
                        <span className='bold'>6.16 </span> <span style={{ fontWeight: "bold", textDecoration: "underline" }} >DIGITAL PAYMENT TERMS
                        </span>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        We provide for a digital payment system which enables the user and Service Providers to connect
                        their account with their respective Bank Account for the purpose of digital payment transaction.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        The users and Providers understand that they are solely responsible for the safety and security
                        of their respective Bank Account Details. Further we try our best to protect the Account Details
                        of the Users and Providers, and we Shall not be liable in any manner in the event of any misuse,
                        hacking of the details of the user’s and Provider’s Bank Account.
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        You hereby warrant and covenant the following
                        <ol >
                            <li>
                                All billing and contact information provided is true and correct;
                            </li>
                            <li>
                                No applicable law shall be violated in making the payment; and
                            </li>
                            <li>
                                No payment shall be made through third party accounts.
                            </li>
                            <li>
                                Any refunds to be made will be made at the sole discretion of us and our team.
                                This refund shall be paid through the same medium as originally received.
                            </li>
                            <li>
                                We use third party payment gateways on the Platform and failures in these
                                gateways would be communicated to the users as it is. Extra currency/conversion
                                charges/deductions/error issues from payment gateway shall not be our
                                responsibility .
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        MODIFICATION OF TERMS AND CONDITIONS
                    </li>
                    <p className='para'>
                        We may at any time modify the terms and conditions (“TERMS”) of the Service without any prior
                        notice or intimation to you. You can access the latest version of the Terms at any given time. You
                        should regularly review the Terms and you are expected to do so at regular intervals. In the event,
                        the modified Terms are not acceptable to you; you should discontinue using the services.
                        However, if you continue to avail the Services provided by the us you irrevocably and
                        unconditionally agree to accept and abide by the modified Terms.
                    </p>
                    <li className='heading'>
                        CONFIDENTIALITY
                    </li>
                    <p className='para'>
                        The parties acknowledge that they shall receive information belonging to the other party that
                        can be reasonably construed to be confidential and privileged information not intended to be
                        disclosed to any third parties or another party unless it needs to be disclosed for the fulfilment
                        of these Terms. The parties agree to use reasonable standards of care to keep such information
                        confidential. Nothing contained herein shall affect the ability of the parties to make disclosures
                        mandated by applicable laws.
                    </p>
                    <li className='heading'>
                        PROPRIETARY RIGHTS
                    </li>
                    <p className='para'>
                        The Company owns and retains all proprietary rights on the Platform. The Platform
                        contains the copyrighted material, trademarks, and other proprietary information of
                        HOPE, and its licensors. Except for that information that is in the public domain such as
                        User r profile or for which permission has been obtained from the User r, you cannot
                        copy, modify, publish, transmit, distribute, perform, display, or sell any such proprietary
                        information. Any such act or an attempted act on your part shall constitute a violation
                        of this Agreement and your Account is liable to be terminated forthwith by the HOPE
                        without refund of any of your unused Subscription fees. We reserve the right to take
                        legal action (civil and/or criminal) wherever applicable for any violations.
                    </p>
                    <li className='heading'>
                        PLATFORM FOR COMMUNICATION
                    </li>
                    <p className='para'>
                        The HOPE is only a venue where User and Providers may meet and interact with one
                        another for their transactions. We do not and cannot be a party to or control in any
                        manner any transaction between two Users of the platform. The use of the Platform by
                        you shall be entirely at your risk and consequences thereof.
                    </p>
                    <p className='para'>
                        Consequently:-
                    </p>
                    <ul>
                        <li>
                            We are not responsible for any non-performance or breach of any contract entered into
                            between Parties. We cannot and do not guarantee that the concerned Parties will
                            perform any transaction concluded on the Platform. We shall not and are not required to
                            mediate or resolve any dispute or disagreement between Parties.
                        </li>
                        <li>
                            We do not make any representation or warranty as to the attributes (such as quality,
                            worth, marketability, etc.) of the service proposed and/or suggested on the Platform. In
                            particular, we do not implicitly or explicitly support or endorse any transactions/services
                            on the Platform. We accept no liability for any errors or omissions, whether on behalf of
                            Platform or third parties.
                        </li>
                        <li>
                            We do not make any representation or warranty as to the attributes (such as legal title,
                            creditworthiness, identity, etc.) of any of our Parties. You are advised and required to
                            independently verify the bona fides of any particular User and Providers that you choose
                            to deal with on the Platform and use your best judgement on that behalf. We will not be
                            responsible for anything in that regard or for the outcome and consequences of your
                            judgement.
                        </li>
                        <li>
                            The Platform is a channel of communication whereby the User can reach a larger base to
                            avail services but at their own risk as to cost and consequences thereof. We are only
                            providing a platform for communication, and it is agreed that the contract for any
                            transactions/services shall be a strictly bipartite contract between the Users without
                            involving us. At no time neither shall any right, title, or interest over the terms of the
                            contract entered into by the two parties vest in us nor shall we have any obligations or
                            liabilities in respect of such contract. We are not responsible for the unsatisfactory or
                            delayed performance of services or non-performance of services.
                        </li>
                        <li>
                            You release and indemnify us and/or all any of our officers and representatives from any
                            claims as to cost, damage, liability or other consequence of any of the actions of the Users
                            and Providers of the HOPE APP and specifically waive any claims that you may have in
                            this behalf under any applicable law by which this Agreement is governed.
                            Notwithstanding our reasonable efforts in that behalf, we cannot control the information
                            provided by other User and Providers which is made available on the Platform. You
                            may find other User’s and Providers information to be offensive, harmful, inaccurate,
                            false, or deceptive. Please use caution, common sense and safe practice while using the
                            Platform. Please note that there are also risks of dealing with unwanted and wrong
                            people as also with people of unsocial elements. You may report any context which you
                            may find offensive by writing to us at support@hopetheapp.com and we shall verify and
                            take necessary action as we may deem fit in our sole discretion.
                        </li>
                    </ul>
                    <li className='heading'>
                        CONTENT POSTED ON WEBSITE
                    </li>
                    <p className='para'>
                        <span className='bold'>11.1 </span>
                        The content listed on the APP are (I) User generated content, ii) Provider generated content,
                        or (iii) HOPE –owned content.
                    </p>
                    <p className='para' >
                        <span className='bold'>11.2 </span>
                        The information that we gather directly or indirectly from the Users and the Providers shall
                        belong to HOPE. Copying of the copyrighted content published by HOPE on the APP for any
                        commercial purpose or for the purpose of earning profit will amounts to violation of
                        copyright and HOPE reserves its rights to initiate legal action under applicable law
                        accordingly.
                    </p>
                    <p className='para'>
                        <span className='bold'>11.3 </span>
                        You understand and agree that we may delete any listing, content, communication, photos
                        or profiles (collectively, "Content") that as per our sole judgment violate this Agreement or
                        which might be offensive, illegal, or that might violate the rights, harm, or threaten the
                        safety of either platform and/or its Users and Providers.
                    </p>
                    <p className='para'>
                        <span className='bold'>11.4 </span>
                        With respect to the content you submit or make available for inclusion on publicly accessible
                        areas of the Platform including but not limited to your contact details, you hereby
                        unconditionally and irrevocably grant us the consent and license to use, store, distribute,
                        reproduce, prepare derivative works of, modify, adapt, publicly perform and publicly display
                        such content on the Platform and to the Users of HOPE community from time to time.
                    </p>
                    <p className='para'>
                        <span className='bold'>11.5 </span>
                        User shall not access the Services for purposes of monitoring their availability, performance,
                        or functionality, or for any other benchmarking or competitive purposes.
                    </p>
                    <p className='para'>
                        <span className='bold'>11.6 </span>
                        You understand and hereby agree that all information, data, text, photographs, graphics,
                        communications, tags, or other content whether publicly posted or privately transmitted or
                        otherwise made available to the Platform are the sole responsibility of the person from
                        whom such content originated and shall be at the user 's/person's sole risks and
                        consequences. This means that you (and not the Company) are solely responsible for all
                        content that you upload, post, email, transmit or otherwise make available via the Service.
                        The HOPE does not control the content posted via the Service and, as such, does not
                        guarantee the accuracy, integrity, or quality of such content. We advise all User /s to
                        independently verify the content /information posted by the other users before acting upon
                        the same and under no circumstances will the we will be liable in any way for any content,
                        including, but not limited to, any errors or omissions in any content, or any loss or damage
                        of any kind incurred as a result of the use of any content posted, emailed, transmitted or
                        otherwise made available via the Service.
                    </p>
                    <p className='para'>
                        <span className='bold'>11.7 </span>
                        We reserve the right to verify the authenticity of content posted on it. In exercising this right,
                        the Platform may ask you to provide any documentary or other form of evidence supporting
                        the content you post on the Platform. If you fail to produce such evidence to oursatisfaction,
                        we may, at our sole discretion, terminate your Account without a refund.
                    </p>
                    <p className='para'>
                        <span className='bold'>11.8 </span>
                        The following is an indicative list of the kind of content that is illegal or prohibited on the
                        Platform. We will investigate and take appropriate legal action in its sole discretion against
                        anyone who violates this Agreement, including without limitation, removing the offending
                        communication content from the Service, and removing the Users Account of such violators
                        without a refund. Illegal and prohibited content includes content which:
                        <ol className='roman'>
                            <li>
                                is blatantly offensive to the community, such as content that promotes racism,
                                bigotry, hatred, or physical harm of any kind against any group or individual;
                            </li>
                            <li>
                                harasses or advocates harassment of another person;
                            </li>
                            <li>
                                involves the transmission of "junk mail", "chain letters," or unsolicited mass mailing
                                or "spamming";
                            </li>
                            <li>
                                promotes information that you know is false, misleading or promotes illegal activities
                                or conduct that is abusive, threatening, obscene, defamatory, or libellous;
                            </li>
                            <li>
                                promotes an illegal or unauthorized copy of another person's copyrighted work, such
                                as providing pirated computer programs or links to them, providing information to
                                circumvent manufacture-installed copy-protect devices, or providing pirated music or
                                links to pirated music files or infringes any patent, trademark, copyright, or other
                                proprietary rights;
                            </li>
                            <li>
                                contains restricted or password-only access pages, or hidden pages or images (those
                                not linked to or from another accessible page);
                            </li>
                            <li>
                                displays pornographic or sexually explicit material of any kind;
                            </li>
                            <li>
                                provides material that exploits people under the age of 18 in a sexual or violent
                                manner, or solicits personal information from anyone under 18;
                            </li>
                            <li>
                                provides instructional information about illegal activities such as making or buying
                                illegal weapons, violating someone's privacy, or providing or creating computer
                                viruses;
                            </li>
                            <li>
                                solicits passwords or personal identifying information for commercial or unlawful
                                purposes from other Users; and
                            </li>
                            <li>
                                engages in commercial activities and/or sales without our prior written consent such
                                as contests, sweepstakes, barter, advertising, and pyramid schemes.
                            </li>
                            <li>
                                Threatens the unity, integrity, defence, security or sovereignty of India, friendly
                                relations with foreign states, or public order or causes incitement to the commission
                                of any cognizable offence or prevents investigation of any offence or is insulting any
                                other nation.
                            </li>
                        </ol>
                    </p>
                    <p className='para'>
                        <span className='bold'>11.9 </span>
                        We hereby inform Users that as per the mandates under the Regulation 3(2) of the IG Rules,
                        they are prohibited to host, display, upload, modify, publish, transmit, update or share any
                        information and user must use our services in a manner consistent with any and all
                        applicable local, state, and national laws, rules and regulations.
                    </p>
                    <p className='para'>
                        <span className='bold'>11.10 </span>
                        Our guidelines prohibit user s to give their name and/or contact details in any form - Email,
                        Phone nos., Messenger Ids, Postal address etc. in any fields other than the specific ones
                        where the same information is asked. If any user who puts the above information in any field
                        other than the one meant for that purpose, then our screening team will remove the same.
                    </p>
                    <li className='heading'>
                        OTHER TERMS OF USE
                    </li>
                    <p className='para'>
                        The other terms of use for the Platform are:
                        <ol className='roman'>
                            <li>
                                User agrees by using this platform that any information shared by you with us or with any
                                Provider will be subject to our Privacy Policy.
                            </li>
                            <li>
                                We reserve the right in its sole discretion to review the activity and status of each account
                                and block access to the user based on such review.
                            </li>
                            <li>
                                We reserve the right at its sole discretion to restrict/suspend your access to the Platform
                                based on review of the user's activity, status or any such criteria we may deems fit and
                                proper with due intimation to you.

                                <p className='para'>
                                    You hereby confirm that as on date of this registration, you do not have any objection to
                                    receive emails, SMS/WhatsApp messages and calls from us and users of the HOPE as long
                                    as you are a registered user of the Platform including SMS permission for authenticating
                                    mobile verification wand transactions via OTP, sent by the Payment Gateway. This
                                    consent shall supersede any preferences set by you with or registration is done with the
                                    Do Not Disturb (DND Register)/ National Customer Preference Register (NCPR). This
                                    consent extends to emails, messages or calls relating but not limited to phone number
                                    verification, the provision for advertising service,
                                </p>
                            </li>
                            <li>
                                Multiple profiles of the users are not allowed on our Site. We reserve the right to
                                deactivate all multiple profiles without any refund of subscription fees.
                            </li>
                            <li>
                                The content that you choose to submit for publication on the platform, including any
                                feedback, ratings, or reviews (“Critical Articles”) relating to Providers or other healthcare
                                professionals, is your sole responsibility. Our role in publishing Critical content is
                                restricted to that of an ‘intermediary’ under the Information Technology Act, 2000. we
                                disclaim all responsibility with respect to the content of Critical content, and its role with
                                respect to such content is restricted to its obligations as an ‘intermediary’ under the said
                                Act. We shall not be liable to pay any consideration to any User for re-publishing any
                                content across any of its platforms.-
                            </li>
                            <li>
                                The provisions of Clause 11 of these Terms apply to your publication of reviews and
                                comments on the APP. Without prejudice to the detailed terms stated in Clause 11, you
                                hereby agree not to post or publish any content on the APP that (a) infringes any third-party intellectual property or publicity or privacy rights, or (b) violates any applicable law
                                or regulation, including but not limited to the IG Rules and SPDI Rules. HOPE, at its sole
                                discretion, may decide not to publish your reviews and feedback, if so, required by
                                applicable law, as per Clause 11 of these Terms. You agree that HOPE may contact you
                                through telephone, email, SMS, or any other electronic means of communication for the
                                purpose of:
                                <ul>
                                    <li>
                                        Obtaining feedback in relation to APP or HOPE’s services; and/or
                                    </li>
                                    <li>
                                        Obtaining feedback in relation to any Providers listed on the APP; and/or
                                    </li>
                                    <li>
                                        Resolving any complaints, information, or queries by Providers regarding your
                                        Critical content
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Except for the purpose of promoting/advertising your profile for matchmaking purposes,
                                you cannot engage in advertising to, or solicitation of, other User to buy or sell any
                                products or services through the Platform.
                            </li>
                            <li>
                                HOPE does not authenticate, vet, screen, endorse or investigate any information or
                                assertion composed in listings, or any other content on the Platform, nor does it in any
                                manner whatsoever certify or attest the same to be correct or true. It does not endorse
                                matches and profiles in any manner.
                            </li>
                            <li>
                                We reserve the right to take appropriate steps to protect the HOPE and/or the HOPE
                                User’s from any abuse/misuse as it deems appropriate in its sole discretion.
                            </li>
                            <li>
                                You cannot use any automated processes, including IRC Bots, EXE's, CGI, or any other
                                programs/scripts to view content on or communicate / contact/respond / interact with
                                or on the Platform and/or its users.
                            </li>
                            <li>
                                We reserve the right to screen communications/advertisements that you may send to
                                other user (s) and also regulate the same by deleting unwarranted/obscene
                                communications/advertisements at any time at its sole discretion without prior notice to
                                any user.
                            </li>
                            <li>
                                Any person who visits the Platform are classified by HOPE as User with incomplete or
                                complete profiles. HOPE reserves the absolute right to modify, amend, change, curtail or
                                expand any privileges, rights, benefits, liabilities, or responsibilities of Users as it deems
                                fit, and these will be instantly applicable to all past, present, and future Users. The
                                quantum of these benefits and even the benefits in totality will be at the sole discretion
                                of the HOPE. HOPE will determine at its own discretion how many benefits it offers to
                                every user.
                            </li>
                            <li>
                                Any profile deleted by the profile user cannot be retrieved under any circumstances.
                            </li>
                            <li>
                                This Agreement, accepted upon use of the Site and further affirmed by becoming a User
                                of the HOPE service, contains the entire agreement between you and HOPE regarding the
                                use of the Site and/or the Service. If any provision of this Agreement is held invalid, the
                                remainder of this Agreement shall continue in full force and effect.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        LINK TO OTHER SITES
                    </li>
                    <p className='para'>
                        The Platform may contain links to other sites and may be governed by respective terms of use
                        not covered herein. Accessing or using these sites shall be at your discretion. We have no control
                        over, and not liable or under obligation for Articles, accuracy, validity, reliability, quality of other
                        sites or made available by/through this platform. We do not take responsibility for any such links
                        to Third Party websites, affiliates, or business partners. Inclusion of any link on the APP does not
                        imply that HOPE endorses the linked site. User may use the links and these services at User’s
                        own risk.
                    </p>
                    <li className='heading'>
                        CORPORATE PARTNERS
                    </li>
                    <p className='para'>
                        We reserve the right to tie up with partners in future to come up with various other online
                        services. We also reserve the right to use/share the information/data collected from Users with
                        its partners if a User’s has already made public the information / data to Users of the Platform.
                        We are not responsible for the conduct of its corporate partners. Any disputes arising between
                        registered Users and the respective corporate partner post availing corporate credits will not be
                        resolved by us.
                    </p>
                    <li className='heading'>
                        INTELLECTUAL PROPERTY
                    </li>
                    <p className='para'>
                        These Terms grants no implied license, right or interest in or to any copyright, patent, trade
                        secret, trademark, URL, domain, invention, or other intellectual property rights of Ours.
                    </p>
                    <p className='para'>
                        You shall not remove, alter, or obscure any copyright, trademark, service mark or other
                        proprietary rights notices incorporated in or accompanying the Platform.
                    </p>
                    <p className='para'>
                        You agree to abide by all applicable intellectual property laws, as well as any additional
                        restrictions contained in the Platform.
                    </p>
                    <p className='para'>
                        All present and future rights in and to any trade secrets, copyright, trademark, or any other
                        proprietary material contained in the Platform shall continue to remain the sole property of Ours,
                        even after the discontinuation of your account for any reason whatsoever.
                    </p>
                    <li className='heading'>
                        COPYRIGHT POLICY
                    </li>
                    <p className='para'>
                        You cannot post, distribute, or reproduce in any way any copyrighted material, trademarks, or
                        other proprietary information without obtaining the prior written consent of the owner of such
                        proprietary rights. Without limiting the foregoing, if you believe that your work has been copied
                        and posted on the HOPE APP in a way that constitutes copyright infringement, please provide us
                        with the following information:
                        <ul>
                            <li>
                                an electronic or physical signature of the person authorized to act on behalf of the
                                owner of the copyright interest;
                            </li>
                            <li>
                                a description of the copyrighted work that you claim has been infringed;
                            </li>
                            <li>
                                a description of where the material that you claim is infringing is located on the
                                website;
                            </li>
                            <li>
                                your address, telephone number, and email address;
                            </li>
                            <li>
                                a written statement by you that you have a good faith belief that the disputed use is
                                not authorized by the copyright owner, its agent, or the law;
                            </li>
                            <li>
                                a statement by you, made under penalty of perjury, that the above information in
                                your Notice is accurate and that you are the copyright owner or authorized to act on
                                the copyright owner's behalf.
                            </li>
                        </ul>
                        <p className='para'>
                            Notice of claims of copyright infringement can be sent to us in writing to the Mumbai address
                            located under the Help/Contact section on the Platform.
                        </p>
                    </p>
                    <li className='heading'>
                        TERMINATION
                    </li>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>17.1 </span>
                        HOPE reserves the right to suspend or terminate a User’s access to the APP and the
                        Services with or without notice and to exercise any other remedy available under law, in
                        cases where,
                        <ul>
                            <li>
                                Such User commits breaches of any terms and conditions of the Agreement;
                            </li>
                            <li>
                                A third-party reports violation of any of its right as a result of your use of the
                                Services;
                            </li>
                            <li>
                                HOPE is unable to verify or authenticate any information provide to HOPE by a
                                User’s;
                            </li>
                            <li>
                                HOPE has reasonable grounds for suspecting any illegal, fraudulent, or abusive
                                activity on part of such Users; or
                            </li>
                            <li>
                                HOPE believes in its sole discretion that User’s actions may cause legal liability for
                                such Users, other Users or for HOPE or are contrary to the interests of the APP.
                            </li>
                        </ul>
                    </p>
                    <p className='para' style={{ marginLeft: "25px" }}>
                        <span className='bold'>17.2 </span>
                        The User may not continue to use the APP under the same account, a different account
                        or re-register under a new account once temporarily suspended, indefinitely suspended or
                        terminated. On termination of an account due to the reasons mentioned herein, such User
                        lost access to data, messages, files, and other content kept on the APP by such User. The
                        User shall ensure that he/she/it has continuous backup of any medical services the User has
                        rendered in order to comply with the User’s record keeping process and practices.
                    </p>
                    <li className='heading'>
                        LIMITATION OF LIABILITY
                    </li>
                    <p className='para'>
                        The HOPE has made available this service as a matter of convenience. We expressly
                        disclaimer any claim or liability arising uploading of any obscene, vulgar, or pornographic
                        images, photographs or a picture or altering or distorting the images available under this
                        service in an obscene, vulgar, or pornographic manner. The Users irrevocably agrees conduct
                        and agrees to indemnify and hereby indemnified the HOPE from any legal actions in relation
                        to such acts. The HOPE reserves the right to terminate your rights to use the Service
                        immediately in case of you uploading of any obscene, vulgar or pornographic images,
                        photographs or pictures or altering or distorting the images available under the service in an
                        obscene, vulgar or pornographic manner, Notwithstanding the penal provisions under the
                        Indian cyber laws or any other allied laws enacted by the government of India or any other
                        statutory, legislative Or regulatory authority authorised in this regard from time to time.
                    </p>
                    <p className='para'>In no event shall the Platform be liable for any direct, indirect, punitive, incidental, special
                        or consequential damages or for any damages whatsoever including, without limitation,
                        damages for loss of use, data or profits, arising out of or in any way connected with the use
                        of performance of the services for interrupted communications, delay, lost data or lost
                        profits arising out of or in connection with this agreement, even if the Platform has been
                        advised of the possibility of damages, or inability to use the services or other related services,
                        the provision of or failure to provide services, or for any information, software, products,
                        services and related graphics obtained through the other websites or services, or otherwise
                        arising out of the use of the other websites or services, whether based on contract, tort,
                        negligence, strict liability or otherwise, even if the Platform or any of its suppliers have been
                        advised of the possibility of damages. We do not endorse in any way any
                        advertisers/contents of advertisers on our Platform.</p>
                    <p className='para'>
                        If you use this Platform, you are responsible for maintaining the confidentiality of your
                        account and password and for restricting access to your computer and you agreed to accept
                        responsibility for all activities that occur under your account and password. The Platform
                        and its affiliates reserve the right to refuse service, terminate account, remove, or edit
                        content, or suspend the Account at their sole discretion.
                    </p>
                    <li className='heading'>
                        DISCLAIMER
                    </li>
                    <p className='para'>The Disclaimer by Company is as such:
                        <ol className='roman'>
                            <li>
                                We are not responsible for any incorrect or inaccurate Content/listing posted on the
                                platform regarding medicine and/or medicinal products including but not limited to
                                dietary supplements etc., have not been evaluated or approved by the Drug
                                Controller of India or any other statutory body. we are not responsible for any
                                problems or technical malfunction of any telephone network or lines, computer on-line systems, servers or providers, computer equipment, software, failure of email or
                                players on account of technical problems or traffic congestion on the Internet or at
                                any website or combination thereof, including injury or damage to users or to any
                                other person's computer related to or resulting and/or in connection with the
                                Platform.
                            </li>
                            <li>
                                Under no circumstances will the HOPE be responsible for any loss or damage resulting
                                from anyone's use of the platform or the Service and/or any content posted on the
                                Platform or transmitted to User. You should not provide your sensitive financial
                                information (for example, your credit card, debit card, bank account information,
                                code, pin etc.), or wire or otherwise send money, to other Users.
                            </li>
                            <li>
                                No User should ever ask for any sensitive bank information from you at any time.
                            </li>
                            <li>
                                The Platform and the service are provided "AS-IS BASIS" and we expressly disclaim
                                any warranty of fitness for a particular purpose or non-infringement. We cannot
                                guarantee and does not promise any specific results from use of the Platform. You
                                understand that the Company makes no guarantees, either express or implied,
                                regarding compatibility with individuals you meet through the Service.
                            </li>
                            <li>
                                We expressly disclaim any liability or responsibility whatsoever and howsoever arising
                                as a result of any content or listing posted on the Platform made available by any
                                Users or the Users of the Platform or any third party.
                            </li>
                            <li>
                                All liability, whether civil or criminal arising out of any content that is posted on the
                                Platform will be of that Users / Users / third party who has Posted such content and
                                the Company reserves its right to claim damages from such Users / third party that it
                                may suffer as a result of such content Posted on the website. The Platform does not
                                claim ownership of Content you submit or make available for inclusion on the service.
                            </li>
                            <li>
                                We shall not be held responsible for any interactions/ passing of information(s) etc.
                                between any Users via e-mail, chat or any other medium with another Users and
                                HOPE has no obligation to monitor any such disputes arising between the Users and
                                HOPE y shall not be a party to such dispute/litigation etc.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        INDEMNITY
                    </li>
                    <p className='para'>
                        You agree to indemnify and hold the Company , its subsidiaries, affiliates, officers, agents, and
                        other partners and employees, fully indemnified and harmless from any loss, liability, claim, or
                        demand, including reasonable attorney's fees, made by any third party due to or arising out of
                        your use of the Service in violation of this Agreement and/or arising from a breach of these Terms
                        of Use and/or any breach of your representations and warranties set forth above and/or any
                        fraudulent act on your part.
                    </p>
                    <li className='heading'>
                        SUGGESTIONS, COMPLAINTS AND DISPUTES
                    </li>
                    <p className='para'>
                        Suggestions and complaints are to be first addressed to the HOPE Customer Support Department
                        at support@hopetheapp.com.
                    </p>
                    <li className='heading'>
                        ELECTRONIC COMMUNICATIVE
                    </li>
                    <p className='para'>
                        When you visit www.hope.com or send emails to us, you are communicating with us
                        electronically. You consent to receive communications from us electronically. We will
                        communicate with you by email or by posting notices on the Platform. You agree that all
                        agreements, notices, disclosures, and other communications that we provide to you
                        electronically satisfy any legal requirement that such communications be in writing.
                    </p>
                    <p className='para'>
                        <strong>
                            By becoming a user of Platform and/or using the Services of the Platform, you unconditionally
                            and irrevocably confirm that you have read and understood the above provisions and agree to
                            abide by them. If you are not agreeing to any of the above terms and conditions please refrain
                            yourself from registering on the Platform.
                            HOPE is a trademark of SOLUTIZE HEALTHCARE PRIVATE LIMITED This Agreement is an
                            electronic document in terms of the Information Technology Act, 2000 and Rules there under
                            pertaining to electronic records as applicable and amended. This electronic record is generated
                            by a computer system and does not require any physical or digital signatures.
                        </strong>
                    </p>
                </ol>
            </p >
        </div >
    )
}

export default HopeTermsAndConditions