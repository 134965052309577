import Config from "../APIUtils/Config";
import axios from "axios";
import Global from "../Global";

const apiCall = (endpoint, body) => {
    Global.authToken = sessionStorage.getItem('authToken') || '';
    return new Promise(async (res, rej) => {
        const authToken = sessionStorage.getItem("authToken");
        try {
            let data = JSON.stringify({
                "header": {
                    "authToken": authToken
                },
                "body": body
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: Config.apiURL + endpoint,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken
                },
                data: data,
                mode: 'cors',
            };
            axios.request(config)
                .then((response) => {
                    if (response.data.responseJson.status == 200) {
                        if (response.data.responseJson.message == 'failure') {
                            rej({
                                status: 200,
                                msg: response.data.responseJson.data.message
                            });
                        } else {
                            res(response.data.responseJson.data);
                        }
                    } else if (response.data.responseJson.status == 400) {
                        rej({
                            status: 400,
                            msg: "Something went wrong, please try again",
                        });
                    } else if (response.status == 401) {
                        Global.authExpired = true;
                        rej({
                            status: 401,
                            msg: "Your login session is expired, Please login again.",
                        });
                    } else if (response.data.responseJson.status == 404) {
                        rej({
                            status: 404,
                            msg: response.data.responseJson.data.message,
                        });
                    } else if (response.data.responseJson.status == 409) {
                        rej({
                            status: 409,
                            msg: response.data.responseJson.data.message,
                        });
                    } else {
                        rej({
                            status: response.data.responseJson.status,
                            msg: "Something went wrong, please try again",
                        });
                    }
                })
                .catch((error) => {
                    try {
                        if (error.response.status == 400) {
                            rej({
                                status: 400,
                                msg: "Something went wrong, please try again",
                            });
                        } else if (error.response.status == 401) {
                            Global.authExpired = true;
                            rej({
                                status: 401,
                                msg: "Your login session is expired, Please login again.",
                            });
                        } else if (error.response.status == 404) {
                            rej({
                                status: 404,
                                msg: error.response.data.responseJson.data.message,
                            });
                        } else if (error.response.status == 409) {
                            rej({
                                status: 409,
                                msg: error.response.data.responseJson.data.message,
                            });
                        } else {
                            rej({
                                status: error.response.status,
                                msg: "Something went wrong, please try again",
                            });
                        }
                    } catch (e) {
                        rej({
                            status: "unknown",
                            msg: "Something went wrong, please try again",
                        });
                    }
                });
        } catch (error) {
            rej({
                status: "unknown",
                msg: "Something went wrong, please try again",
            });
        }
    });
}
export { apiCall };

