import { signIn, confirmSignIn, fetchAuthSession, signOut } from 'aws-amplify/auth';
import Global from '../Global';
import { Amplify } from 'aws-amplify';

const handle_Sign_Up_Sign_In_Errors = (rej, e) => {
    if (e.name == "InvalidParameterException") {
        const errorMessage = {
            msg: "One or more of the parameters provided during sign-up/sign-in are invalid."
        }
        rej(errorMessage);
    } else if (e.name == "CodeDeliveryFailureException") {
        const errorMessage = {
            msg: "Otp could not be sent to the user."
        }
        rej(errorMessage);
    } else if (e.name == "InternalErrorException") {
        const errorMessage = {
            msg: "An internal error occurred."
        }
        rej(errorMessage);
    } else if (e.name == "TooManyRequestsException") {
        const errorMessage = {
            msg: "limit exceeded."
        }
        rej(errorMessage);
    } else if (e.name == "TooManyFailedAttemptsException") {
        const errorMessage = {
            msg: "The user has exceeded the allowed number of unsuccessful sign-in attempts."
        }
        rej(errorMessage);
    } else if (e.name == "PasswordResetRequiredException") {
        const errorMessage = {
            msg: "The user's password is expired or needs to be reset."
        }
        rej(errorMessage);
    } else if (e.name == "LimitExceededException") {
        const errorMessage = {
            msg: "Attempt limit exceeded, please try after some time."
        }
        rej(errorMessage);
    } else if (e.name == "Unknown") {
        const errorMessage = {
            msg: "Please check your internet connection and try again."
        }
        rej(errorMessage);
    }
    else {
        rej(e)
    }
}

const handle_Otp_Errors = (rej, e) => {
    if (e.name == "InvalidParameterException") {
        const errorMessage = {
            msg: "One or more of the parameters provided during sign-up are invalid."
        }
        rej(errorMessage);
    } else if (e.name == "CodeDeliveryFailureException") {
        const errorMessage = {
            msg: "Otp could not be sent to the user."
        }
        rej(errorMessage);
    } else if (e.name == "InternalErrorException") {
        const errorMessage = {
            msg: "An internal error occurred."
        }
        rej(errorMessage);
    } else if (e.name == "TooManyRequestsException") {
        const errorMessage = {
            msg: "limit exceeded."
        }
        rej(errorMessage);
    } else if (e.name == "CodeMismatchException") {
        const errorMessage = {
            msg: "Please enter correct otp."
        }
        rej(errorMessage);
    } else if (e.name == "ExpiredCodeException") {
        const errorMessage = {
            msg: "Otp has been expired."
        }
        rej(errorMessage);
    } else if (e.name == "NotAuthorizedException") {
        const errorMessage = {
            msg: "Please login again your session is expired"
        }
        rej(errorMessage);
    } else if (e.name == "LimitExceededException") {
        const errorMessage = {
            msg: "Limit exceeded as too many requests were made to the service."
        }
        rej(errorMessage);
    } else if (e.name == "Unknown") {
        const errorMessage = {
            msg: "Please check your internet connection and try again."
        }
        rej(errorMessage);
    }
    else {
        rej(e)
    }
}

const handle_access_token_errors = (rej, e) => {
    if (e.name === "NetworkError") {
        const errorMessage = {
            msg: "Network error occurred while fetching tokens."
        };
        rej(errorMessage);
    } else if (e.name === "ServiceUnavailableError") {
        const errorMessage = {
            msg: "The authentication service is currently unavailable."
        };
        rej(errorMessage);
    } else if (e.name === "InvalidTokenError") {
        const errorMessage = {
            msg: "The fetched token is invalid or expired."
        };
        rej(errorMessage);
    } else if (e.name === "UnauthorizedError") {
        const errorMessage = {
            msg: "Unauthorized access to fetch tokens."
        };
        rej(errorMessage);
    } else if (e.name == "Unknown") {
        const errorMessage = {
            msg: "Please check your internet connection and try again."
        }
        rej(errorMessage);
    }
    else {
        rej(e);
    }
}

const handlesignIn = (from, number) => {// number must be with country code for example "+919284393181"
    if (from) {
        const adminConfig = {
            Auth: {
                identityPoolId: "ap-south-1:7f20cdac-73e5-4fa6-9a02-21f39d06f08f",
                region: "ap-south-1",
                mandatorySignIn: false,
                userPoolWebClientId: "5anuerhvjhc9fcn1ug7ugdl9cv",
                Cognito: {
                    userPoolId: "ap-south-1_f6SKLeQ53",
                    userPoolClientId: "5anuerhvjhc9fcn1ug7ugdl9cv"
                }
            }
        };
        Amplify.configure(adminConfig);
        return new Promise(async (res, rej) => {
            try {
                signOut();
                const formatedUsername = "+91" + number
                const password = "Hope@" + number.slice(-5);
                const user = await signIn({
                    username: formatedUsername,
                    password: password,
                    options: {
                        authFlowType: 'USER_SRP_AUTH',
                    },
                });
                Global.auth.isRegistered = true;
                res(user);
            } catch (e) {
                if (e.name == 'UserAlreadyAuthenticatedException') {
                    try {
                        await getAccessToken(true, number);
                        return res(true);
                    } catch (error) {
                        rej(error);
                    }
                }
                if (e.name == 'NotAuthorizedException' || e.name == 'UserNotFoundException' || e.name == 'UserNotConfirmedException') {
                    try {
                        if (e.message == "User is disabled.") {
                            const errorMessage = {
                                // msg : e.message
                                msg: "Your profile has been deactivated. Please contact admin at support@hopetheapp.com for further information."
                            }
                            rej(errorMessage)
                        }
                    } catch (err) {
                        console.log(err, "error");
                        rej(err);
                    }
                    try {
                        if (e.message == "Incorrect username or password.") {
                            const errorMessage = {
                                // msg : e.message
                                msg: "You are not a registered user, Kindly register the number in hope app."
                            }
                            rej(errorMessage)
                        }
                    } catch (err) {
                        console.log(err, "error");
                        rej(err);
                    }
                } else {
                    handle_Sign_Up_Sign_In_Errors(rej, e);;
                }
            }
        });
    } else {
        const defaultConfig = {
            Auth: {
                identityPoolId: "ap-south-1:7f20cdac-73e5-4fa6-9a02-21f39d06f08f",
                region: "ap-south-1",
                mandatorySignIn: false,
                userPoolWebClientId: "20ctkv99u8ass8ioef45cv4m5i",
                Cognito: {
                    userPoolId: "ap-south-1_qYYwqxObk",
                    userPoolClientId: "20ctkv99u8ass8ioef45cv4m5i"
                }
            }
        };
        Amplify.configure(defaultConfig)
        return new Promise(async (res, rej) => {
            try {
                // signOut();
                const formatedUsername = "+91" + number
                const password = "Hope@" + number.slice(-5);
                const user = await signIn({
                    username: formatedUsername,
                    password: password,
                    options: {
                        authFlowType: 'USER_SRP_AUTH',
                    },
                });
                Global.auth.isRegistered = true;
                res(user);
            } catch (e) {
                if (e.name == 'UserAlreadyAuthenticatedException') {
                    try {
                        const mumbai = "+91" + number
                        await getAccessToken(true, mumbai);
                        return res(true);
                    } catch (error) {
                        rej(error);
                    }
                }
                if (e.name == 'NotAuthorizedException' || e.name == 'UserNotFoundException' || e.name == 'UserNotConfirmedException') {
                    try {
                        if (e.message == "User is disabled.") {
                            const errorMessage = {
                                // msg : e.message
                                msg: "Your profile has been deactivated. Please contact admin at support@hopetheapp.com for further information."
                            }
                            rej(errorMessage)
                        }
                    } catch (err) {
                        console.log(err, "error");
                        rej(err);
                    }
                    try {
                        if (e.message == "Incorrect username or password.") {
                            const errorMessage = {
                                // msg : e.message
                                msg: "You are not a registered user, Kindly register the number in hope app."
                            }
                            rej(errorMessage)
                        }
                    } catch (err) {
                        console.log(err, "error");
                        rej(err);
                    }
                } else {
                    handle_Sign_Up_Sign_In_Errors(rej, e);;
                }
            }
        });
    }
}

const getAccessToken = (isAuthenticated, number) => {// number must be with country code for example "+919284393181"
    return new Promise(async (res, rej) => {
        try {
            const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
            sessionStorage.setItem("authToken", accessToken.toString());
            if (isAuthenticated && number && number == idToken.payload.phone_number) {
                Global.authToken = accessToken.toString();
                Global.auth.isRegistered = true;
            } else if (!isAuthenticated) {
                Global.authToken = accessToken.toString();
                Global.auth.isRegistered = true;
            } else {
                await signOut();
                await handlesignIn(true, number);
            }
            res(true);
        } catch (e) {
            console.log('signin error', e)
            handle_access_token_errors(rej, e);
        }
    });
}

const handleconfirmSignIn = (otp) => {
    return new Promise(async (res, rej) => {
        try {
            const challengeResponse = otp;
            const result = await confirmSignIn({ challengeResponse });
            res(result);
        } catch (e) {
            handle_Otp_Errors(rej, e);
        }
    })
}

export { handlesignIn, handleconfirmSignIn, getAccessToken };