import React, { useEffect, useState } from 'react'
import './update-consent-styles.css';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import axios from "axios";
import SecondaryHeader from '../../Components/SecondaryHeader.js';
import WarningModal from '../../DashboardComponents/WarningModal/warningModal';
import CustomloaderSpinner from '../../DashboardComponents/LoaderSpinner/loader.jsx';
import { apiCall } from '../../Utils/APIUtils/ApiUtils';

function UpdateConsent() {

    const [reqidData, setReqId] = useState('');
    const [patientIdData, setPatientId] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [updateConsentResponse, setupdateConsentResponse] = useState(false);
    const [buttonClickStatus, setbuttonClickStatus] = useState("");

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);



    const openErrorModal = (message) => {
        setErrorMessage(message);
        setIsErrorModalOpen(true);
    };

    const closeErrorModal = () => {
        setIsErrorModalOpen(false);
        setErrorMessage('');
    };

    useEffect(() => {
        document.title = 'HOPE | Update Consent';
        const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
        const reqid = urlParams.get('reqid');
        const patientid = urlParams.get('patientid');

        if (reqid && patientid) {
            setReqId(reqid);
            setPatientId(patientid);
        } else {
            setIsValid(false);
        }
    }, []);

    useEffect(() => {
        if (reqidData && patientIdData) {
            validateConsentRequest();
        } else {
            setIsValid(false);
        }
    }, [reqidData, patientIdData])


    const validateConsentRequest = async () => {
        try {
            const body = {
                "transactionId": reqidData.toString(),
                "coord": [
                    "24.623061",
                    "10.830960"
                ],
                "location": "Mumbai",
                "deviceInfo": "android"
            }
            setIsLoading(true);
            const response = await triggerApiCall('admin/validateconsentrequest', body);
            if (response.message === "Valid") {
                setIsValid(true);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            openErrorModal("Something went wrong, please try again");
        }
    };


    const updateConsentApi = async (reqId, patientId, status) => {
        try {
            const body = {
                "reqId": reqId.toString(),
                "patientId": patientId.toString(),
                "status": status,
                "coord": [
                    "24.623061",
                    "10.830960"
                ],
                "location": "Mumbai",
                "deviceInfo": "android"
            }
            setIsLoading(true);
            const response = await triggerApiCall('admin/updateconsent', body);
            setupdateConsentResponse(true);
            setbuttonClickStatus(status);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            openErrorModal("Something went wrong, please try again");
        }
    };

    const triggerApiCall = (endpoint, body) => {
        return new Promise(async (res, rej) => {
            try {
                const response = await apiCall(endpoint, body);
                res(response);
            } catch (error) {
                rej(error);
            }
        })
    };

    return (
        <div>
            <SecondaryHeader />
            <div className="main-box">
                {isValid ? (
                    <>
                        {updateConsentResponse ? (
                            <div className="after_btn_click_txt">
                                {
                                    buttonClickStatus === "Approved" ?
                                        <div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
                                            <DoneIcon className='tick' style={{ fontSize: 70, marginRight: 8 }} />
                                            <h1>{buttonClickStatus} Successfully</h1>
                                        </div>

                                        :
                                        <div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
                                            <CloseIcon className='cross' style={{ marginRight: 8, fontSize: 70 }} />
                                            <h1>{buttonClickStatus} Successfully</h1>
                                        </div>

                                }
                            </div>
                        ) : (
                            <div className="box-outline">
                                <div className="inside_content">
                                    <div className="info_message">
                                        <p className='info_message_text'>Dear User,<br />You have to verify and give permission to User to manage HOPE health Account.</p>
                                    </div>
                                    <div className="button_container">
                                        <div className="reject">
                                            <button onClick={() => { updateConsentApi(reqidData, patientIdData, "Rejected") }} className="commonButtonWithShadowHollow" style={{ marginTop: 24, paddingRight: 12 }}>
                                                <CloseIcon className='cross' style={{ marginRight: 8 }} />
                                                Reject
                                            </button>
                                        </div>
                                        <div className="accept">
                                            <button onClick={() => { updateConsentApi(reqidData, patientIdData, "Approved") }} className="commonButtonWithShadowHollow" style={{ marginTop: 24, paddingRight: 12 }}>
                                                <DoneIcon className='tick' style={{ marginRight: 8 }} />
                                                Accept
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ >
                ) : (
                    <div className="error-message">
                        <ErrorOutlineIcon className="error-icon" style={{ fontSize: 80, marginTop: 30 }} />
                        <p style={{ marginTop: 15, fontSize: 30 }}>Invalid URL</p>
                    </div>
                )}
            </div>
            <WarningModal
                isOpen={isErrorModalOpen}
                errorMessage={errorMessage}
                onClose={closeErrorModal}
            />
            {isLoading ? <CustomloaderSpinner /> : null}
        </div >
    )
}

export default UpdateConsent;