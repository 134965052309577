import React from 'react';
import './HopePrivacyPolicy.css';

const HopePrivacyPolicy = () => {

    return (
        <div className={`hope-privacy-policy-main-container`}>
            <div className={`title-container`}>
                <p className={`title`}>PRIVACY POLICY</p>
            </div>
            <p className='para'>
                <span className={`bold`}>SOLUTIZE HEALTHCARE PRIVATE LIMITED</span> having its registered office currently located at C4504 DB Woods, Krishna Vatika Marg, Gokuldham, Goregaon East, Mumbai, Maharashtra,
                India, 400063 (Company) is the author and publisher of the internet resource
                <span className={`bold`}> www.HOPEtheapp.com (“Website”)</span> on the world wide web as well as the software and
                applications provided by <span className={`bold`}>HOPE</span>, including but not limited to the mobile applications <span className={`bold`}>HOPE </span>
                App<span className={`bold`}>(“us”, “we”, or “HOPE”, which also includes its affiliates)</span> (together with the Website,
                referred to as the <span className={`bold`}>“Platform”</span>).
            </p>
            <p className='para'>
                <span className={`bold`}>HOPE</span> recognizes the gravity and importance of personal information and accordingly has
                composed this Privacy Policy, ensuring complete safety and upkeep of best interests of those
                who avail the services from HOPE APP. By downloading the Application, you agree and accept
                the terms of collection of Information. The use of this App at all times shall be subject to the
                terms of this Privacy Policy and Terms of Service. This Privacy Policy is an integral part of our
                Terms of Use and therefore, any capitalized term used but not defined in this Privacy Policy
                shall have the meaning attributed to it in our Terms of Use.
            </p>
            <p className='para'>
                <span className={`bold`}>HOPE</span> may, for the purpose of providing its services, obtain and receive the following types of
                information from you or your system.
            </p>
            <p className='para'>
                <ul>
                    <li>
                        <span className='bold'>HOPE</span> recognizes the importance of privacy of its users and of maintaining
                        confidentiality of the information provided by its users as a responsible data controller
                        and data processor.
                    </li>
                    <li>
                        This Privacy Policy provides for the practices for handling and securing user's Personal
                        Information (defined hereunder) by <span className='bold'>HOPE</span> and its subsidiaries and affiliates.
                    </li>
                    <li>
                        This Privacy Policy applies to any person ('User') who makes a purchase, or intends to
                        purchase, or inquire about any product(s) or service(s) made available by HOPE through
                        any of HOPE's customer interface channels including its website, mobile site, mobile app
                        & offline channels such as call centres and offices (collectively referred herein as "Sales
                        Channels").
                    </li>
                    <li>
                        By using/availing the services and/or by otherwise providing us your information
                        general or personal, you shall be deemed to have read, understood, and agreed to the
                        practices and policies outlined in this Privacy Policy and further agree to be bound by
                        the Privacy Policy of HOPE.
                    </li>
                    <li>
                        The User hereby provides us with their consent to collect, use, share and disclose their
                        information whenever required as described in this Privacy Policy.
                    </li>
                    <li>
                        The User further agrees and accepts that the right to change, modify, add, or delete
                        portions of the terms of this Privacy Policy are reserved by us and the same shall be
                        incorporated at our sole discretion, at any time.
                    </li>
                    <li>
                        The Users are advised to refrain from using/availing any of our services or give us any of
                        your information if they do not agree with this Privacy Policy.
                    </li>
                    <li>
                        In case a user is using/availing the services on behalf of any third party (such as a minor,
                        a child, a person with any imparity making him/her incapable of using electronic devices,
                        a patient incapable of using electronic devices due to illness), such third party user
                        represents that the User possess complete authority for acting on behalf of such individual and to (i) accept this Privacy Policy on such individual’s behalf, and (ii) provide
                        consent on behalf of such individual to collect, use and disclose such individual’s
                        information as described in this Privacy Policy.
                    </li>
                    <li>
                        For the purpose of this Privacy Policy, wherever the context so requires "you" or "your"
                        shall mean User.
                    </li>
                    <li>
                        This Privacy Policy does not apply to any third-party website(s), mobile sites and mobile
                        apps, even though their websites/products are linked to our Platform User is to take
                        note that information and privacy practices of Company's business partners,
                        advertisers, sponsors, or other sites to which HOPE provides hyperlink(s), may not be
                        exactly same as this Privacy Policy. Accordingly, it is highly recommended to review the
                        privacy statements and policies of any such third-party website(s) with whom they
                        correspond or connect to.
                    </li>
                </ul>
            </p>
            <p className='para'>
                <ol>
                    <li className='heading'>
                        LAWS GOVERNING THE PRIVACY POLICY:
                    </li>
                    <p className='para-1'>
                        This Privacy Policy is published on the Platform in due compliance with, inter alia:
                        <ol>
                            <li>
                                Section 43A of the Information Technology Act, 2000;
                            </li>
                            <li>
                                Regulation 4 of the Information Technology (Reasonable Security Practices and
                                Procedures and Sensitive Personal Information) Rules, 2011 (the <span className={`bold`}>“SPDI
                                    Rules”</span>);
                            </li>
                            <li>
                                Regulation 3(1) of the Information Technology (Intermediaries Guidelines)
                                Rules, 2011.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        COLLECTION OF PERSONAL INFORMATION AND ITS SIGNIFICANCE:
                    </li>
                    <p className='para-1'>
                        To a significant extent, the Services provided by us require us to know who the
                        concerned person is and what he/she/they is looking to avail from HOPE, so that we
                        can help to meet their needs with most efficiency. When any user accesses/avails any
                        of the services, or makes any correspondence with us via emails, telephonic
                        conversation or by using other modes of correspondence, we may ask the User to
                        provide us at their sole discretion, certain information that identifies the User
                        personally or could be used to personally identify such a User. The User hereby
                        provides their consent to Company to collect such information. Without prejudice to
                        the generality of the above, information collected by us from our User shall be
                        including but not limited to the following:
                        <ol>
                            <li>
                                Contact Details (such as email address and/or phone number);
                            </li>
                            <li>
                                Statistical data (such as gender, date of birth and pin code of the area of their
                                residence);
                            </li>
                            <li>
                                Data related to the usage of the services and history of the appointments,
                                orders placed, services availed, connections made with other patients, if any,
                                made by the User through the Services available on Platform;
                            </li>
                            <li>
                                Insurance data (such as insurance carrier and current insurance plan, if any);
                            </li>
                            <li>Additional information that users may choose to provide to us voluntarily,
                                which can include data shared via emails or letters, as well as any images and
                                other documents/files/medical history.
                            </li>
                        </ol>
                        <p className='para-1'>
                            The information collected from the User by Company may constitute ‘personal
                            information’ or ‘sensitive personal data or information’ under the SPDI Rules.
                        </p>
                        <p className='para-1'>
                            <span className='bold'>“Personal Information”</span> as defined under the SPDI Rules is, to mean any information
                            that relates to a natural person, which, either directly or indirectly, in combination
                            with other information available or likely to be available to a body corporate, is
                            capable of identifying such person.
                        </p>
                        <p className='para-1'>
                            The SPDI Rules further define <span className='bold'>“Sensitive Personal Data or Information”</span> of a person to
                            mean personal information about that person relating to:
                            <ol>
                                <li>
                                    passwords;
                                </li>
                                <li>
                                    financial information such as bank accounts, credit and debit card details or other
                                    payment instrument details;
                                </li>
                                <li>
                                    physical, physiological and mental health condition;
                                </li>
                                <li>
                                    sexual orientation;
                                </li>
                                <li>
                                    medical records and history;
                                </li>
                                <li>
                                    biometric information;
                                </li>
                                <li>
                                    information received by body corporate under lawful contract or otherwise;
                                </li>
                                <li>
                                    visitor details as provided at the time of registration or thereafter; and
                                </li>
                                <li>
                                    call data records.
                                </li>
                            </ol>
                            <p className='para-1'>
                                Company shall be at its sole discretion to use, collect and disclose information that is
                                freely available in the public domain without taking prior consent from the respective
                                User.
                            </p>
                        </p>
                    </p>
                    <li className='heading'>
                        USE OF PERSONAL INFORMATION BY HOPE:
                    </li>
                    <p className='para-1'>
                        <ol className='abcd'>
                            <li>
                                You understand, agree, and acknowledge that Company may collect information
                                about the devices you use to access the APP and anonymous usage data for the sole
                                purpose of enhancing the quality of its services and developing new ones.
                            </li>
                            <li>
                                You understand, agree, and acknowledge that the Platform grants Company access
                                to the personal contact details (such as email or phone number) of registered Users
                                for the purpose of facilitating communication, scheduling appointments, and
                                receiving feedback regarding providers and their services to enhance user
                                convenience.
                            </li>
                            <li>
                                While placing an order or booking an appointment, we may use Personal
                                Information including payment details which include cardholder name, credit/debit
                                card number, unified payment interface ID (in encrypted form) with date of expiry,
                                bank details, wallet details, etc. as shared and allowed to be stored by the User. This
                                information is presented to the User at the time of making a booking to enable the
                                User to complete their bookings expeditiously.
                            </li>
                            <li>
                                Personal Information may also be used for several reasons including but not limited
                                to:
                            </li>
                            <ul className='dash'>
                                <li>
                                    confirm your order or appointment or request of service/s, with respective
                                    service providers;
                                </li>
                                <li>
                                    keep the user updated about the transaction status;
                                </li>
                                <li>
                                    send confirmations of placing order/cancellation of order or details of
                                    appointment which the User has booked on the App, either via sms or Whatsapp or any other messaging service, the permission of which is provided
                                    by the User;
                                </li>
                                <li>
                                    send any modifications or changes made to users’ booking(s);
                                </li>
                                <li>
                                    allow the customer service of HOPE to contact the user, if necessary;
                                </li>
                                <li>
                                    customize the content of our website, mobile site and mobile app;
                                </li>
                                <li>
                                    request for reviews of products or services or any other improvements;
                                </li>
                                <li>
                                    send verification message(s) or email(s);
                                </li>
                                <li>
                                    Validate/authenticate Users' account and to prevent any misuse or abuse of
                                    the same.
                                </li>
                                <li>
                                    To provide live updates on delivery status of products purchased by you.
                                </li>
                            </ul>
                        </ol>
                    </p>
                    <li className='heading'>
                        USERS OUTSIDE THE GEOGRAPHICAL LIMITS OF INDIA
                    </li>
                    <p className='para-1'>
                        <ul>
                            <li>
                                Kindly note that the data shared with Company shall be primarily processed in
                                India and such other jurisdictions where a third party engaged by company E may
                                process the data on its behalf. By agreeing to this policy, you are providing
                                company with your express consent to process your personal information for the
                                purpose(s) defined in this policy. The data protection regulations in India or such
                                other jurisdictions mentioned above may differ from those of your country of
                                residence.
                            </li>
                            <li>
                                You understand, agree, and acknowledge that by registering yourself with the
                                Platform you grant the consent in favour of Company to process your data. In the
                                event If you have any concerns regarding processing of your data you may choose
                                to not to register on the Platform.
                            </li>
                            <li>
                                You understand, agree, and acknowledge that such processing of data is essential
                                for us to be able to provide the service(s) as requested from you, to confirm your
                                bookings, or orders. For instance, if you place an order or reserve a booking
                                (product, service or equipment), then we might need to share certain personal
                                information of yours like contact details, gender, baseline assessment, signs and
                                symptoms, previous medical record which may require specific attention or facility
                                etc. with our vendors, which they may further process the information for making
                                suitable arrangements for your treatment plan.
                            </li>
                            <li>
                                Non- consent by you for us to process your information may:
                                <ol className='abcd'>
                                    <li>
                                        severely inhibit our ability to serve you properly and in such case, we may have
                                        to refuse the booking of the service altogether, or
                                    </li>
                                    <li>
                                        unreasonably restrict us to service your booking (if a booking is already made)
                                        which may further affect your treatment plan or may compel us to cancel your
                                        booking.
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </p>
                    <li className='heading'>
                        FEEDBACK:
                    </li>
                    <p className='para-1'>
                        HOPE is dedicated to being a user-friendly platform, and we continuously seek
                        feedback, opinions, comments, and suggestions from our active Users to enhance our
                        services. We value the input from our Users, as it helps us improve and provide better
                        services. Providing feedback and reviews is entirely optional, as we respect it as a
                        personal choice. However, we encourage Users who choose to provide honest
                        feedback, as it contributes to our ongoing improvement efforts. The feedback and reviews we receive may be used for training, development, and improvement of our
                        services.
                    </p>
                    <li className='heading'>
                        HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?
                    </li>
                    <p className='para-1'>
                        The information of a user shall be retained by us only till the time such a user’s account
                        remains active with us. We shall not and do not intend to retain such information for
                        longer than is required for which the information may lawfully be used or is otherwise
                        required under any other law for the time being in force save and except for such
                        information where we have obtained specific cost for retention. The information / data
                        provided to us by a user shall be after a certain period of time made unrecognizable and
                        aggregated, and then shall be held by us to provide our Services effectively. However,
                        the usage of such unrecognised or anonymized data shall be solely for analytical
                        purposes. The user is requested to note that the Non-consent, or cancellation/closure
                        of account shall result in, inability of Platform to provide its Services to such user or to
                        terminate any existing relationship which exists between Company and the user.
                    </p>
                    <li className='heading'>
                        COOKIES:
                    </li>
                    <p className='para-1'>
                        <ol className='abcd'>
                            <li>
                                The Website employs temporary cookies to store specific non-sensitive information,
                                which can be utilized by Company and its service providers for the technical
                                management of the Website, research, and development, as well as user
                                administration. In the context of serving advertisements or improving services for
                                its users, Company may permit authorized and reputable third parties to set or
                                recognize a unique cookie on the user's browser. It's important to note that these
                                cookies do not retain any Personal Information of the user. Users have the option to
                                disable these cookies. Disabling cookies on the website will not hinder users from
                                using the Website, but it may limit access to certain features.
                            </li>
                            <li>
                                Company has no control or hold over the sites displayed as search results or links
                                generated from within its Services. It is possible that such other sites may place their
                                own cookies or other files on the user’s electronic gadget, collect data or solicit
                                personal information from the users, and in case of any leak reported of such
                                personal information of a user on any such random websites, the user hereby agrees
                                to not to hold Company responsible or liable for any such leak and not to claim any
                                damages from Company Consequently, Company does not make any
                                representations related to the privacy practices or policies of such third-party
                                websites or links or terms of use of such websites, nor does Company guarantees
                                or predicts the accuracy, integrity, or quality of the information, data, visuals,
                                software, sound, images, graphics, videos, messages, or other materials available on
                                such websites. The insertion or removal does not imply or tend to imply any approval
                                by Company of the website, the website's provider, or the information available on
                                the website. The User hereby agrees and confirms that any visit to such third-party
                                websites or links are made by them at their sole discretion and entirely at their own
                                risk. Company encourages and advises in good faith to the User to read the privacy
                                policies of such third-party websites before engaging in any transaction with them.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        SHARING OF USERS' PERSONAL INFORMATION:
                    </li>
                    <p className='para-1'>
                        <ul>
                            <li>
                                <span className='bold'>Service Providers and suppliers:</span>
                            </li>
                            <ol className='abcd'>
                                <li>
                                    Your information shall be shared with the service providers like healthcare
                                    professionals, Medical Equipment rent/purchase suppliers, medicine delivery
                                    service providers, or any other suppliers <span className='bold'>("said service providers")</span> who are
                                    responsible for fulfilling your purchase. You may note that while making a service
                                    booking and/or while placing an order for products through Platform you
                                    authorize us to share your information with the said service providers and
                                    suppliers. It is pertinent to note that Company does not authorize the end service
                                    provider to use your information for any other purpose(s) except as may be for
                                    fulfilling their part of service. However, how the said service providers/suppliers
                                    use the information shared with them is beyond the purview and control of
                                    Company as they process Personal Information as independent data controllers,
                                    and hence we cannot be made accountable for the same. You are therefore
                                    advised to review the privacy policies of the respective service provider or
                                    supplier whose services you choose to avail.
                                </li>
                                <li>
                                    Company does not sell or rent individual customer names or other Personal
                                    Information of Users to third parties except sharing of such information with our
                                    business/alliance partners or vendors who are engaged by us for providing
                                    various services to our customers from time to time.
                                </li>
                            </ol>
                            <li>
                                <span className='bold'>Companies in the same group:</span>
                            </li>
                            <ol className='abcd'>
                                <li>
                                    In view of improving personalization and service efficiency, we may, under controlled
                                    and secured circumstances, share your Personal Information with our affiliate or
                                    associate entities. This will enable us to provide you with information about various
                                    products and services, which you might require; or help us address your questions and
                                    requests in relation to your requests of availing various services.
                                </li>
                                <li>
                                    If the assets of Company are acquired, our customer information may also be
                                    transferred to the acquirer depending upon the nature of such acquisition. In addition,
                                    as part of business expansion/development/restructuring or for any other reason
                                    whatsoever, if we decide to sell/transfer/assign our business, any part thereof, any of
                                    our subsidiaries or any business units, then as part of such restructuring exercise
                                    customer information including the Personal Information collected herein shall be
                                    transferred accordingly.

                                </li>
                            </ol>
                            <li>
                                <span className='bold'>Business partners and third-party vendors:</span>
                            </li>
                            <ol className='abcd'>
                                <li>
                                    We may also share certain filtered Personal Information to our corporate
                                    affiliates or business partners who may contact the customers to confirm or
                                    deliver certain products or services, which may include free or paid
                                    products/services, which will enable the users to have better healthcare
                                    experience or to avail certain benefits specially made for Platform users.
                                </li>
                                <li>
                                    Company may share your Personal Information to third party that Company
                                    may engage to perform certain tasks on its behalf, including but not limited to
                                    payment processing, data hosting, and data processing platforms.
                                </li>
                                <li>
                                    We use non-identifiable Personal Information of Users in aggregate or
                                    anonymized form to build higher quality, more useful online services by performing statistical analysis of the collective characteristics and behaviour
                                    of our customers and inquirers, and by measuring demographics and interests
                                    regarding specific areas of the Website. We may provide anonymous statistical
                                    information based on this data to suppliers, advertisers, affiliates, and other
                                    current and potential business partners. We may also use such aggregate data
                                    to inform these third parties as to the number of people who have seen and
                                    clicked on links to their websites. Any Personal Information which we collect
                                    and which we may use in an aggregated format is our property. We may use
                                    it, in our sole discretion and you shall not be entitled for claiming any
                                    compensation to you, for any legitimate purpose including without limitation
                                    the commercial sale thereof to third parties.
                                </li>
                                <li>
                                    Occasionally, Company will hire a third party for market research, surveys etc.
                                    and will provide information to these third parties specifically for use in
                                    connection with these projects. The information (including aggregate cookie
                                    and tracking information) we provide to such third parties, alliance partners,
                                    or vendors are protected by confidentiality agreements and such information
                                    is to be used solely for completing the specific project, and in compliance with
                                    the applicable regulations.
                                </li>
                            </ol>
                            <li>
                                <span className='bold'>Disclosure of information:</span>
                            </li>
                            <ol className='abcd'>
                                <li>
                                    In addition to the circumstances described above, Company may disclose User's
                                    Personal Information if required to do so:
                                </li>
                                <ol>
                                    <li>
                                        by law, required by any enforcement authority for investigation, by
                                        court order or in reference to any legal process;
                                    </li>
                                    <li>
                                        to conduct our business;
                                    </li>
                                    <li>
                                        for regulatory, internal compliance and audit exercise(s)
                                    </li>
                                    <li>
                                        to secure our systems; or
                                    </li>
                                    <li>
                                        to enforce or protect our rights or properties of Company or any or all
                                        of its affiliates, associates, employees, directors or officers or when we
                                        have reason to believe that disclosing Personal Information of User(s)
                                        is necessary to identify, contact or bring legal action against someone
                                        who may be causing interference with our rights or properties, whether
                                        intentionally or otherwise, or when anyone else could be harmed by
                                        such activities.
                                    </li>
                                </ol>
                                <li>
                                    Such disclosure and storage may take place without your knowledge. In that
                                    case, we shall not be liable to you or any third party for any damages howsoever
                                    arising from such disclosure and storage.
                                </li>
                            </ol>
                        </ul>
                    </p>
                    <li className='heading'>
                        USER GENERATED CONTENT
                    </li>
                    <p className='para-1'>
                        <ol className='abcd'>
                            <li>
                                HOPE provides an option to its users to post their experiences by way of reviews,
                                ratings, and general poll questions. The customers also have an option of posting
                                questions w.r.t a service offered by Company or post answers to questions
                                raised by other users. We also provide a platform to connect with other users
                                who have registered with Platform. The Users may receive emails, notifications
                                (app, SMS, WhatsApp, or any other messaging service) to share their review(s), answer questions posted by other users. The reviews written or posted may also
                                be visible to other Users.
                            </li>
                            <li>
                                The UGC that Company collects may be of the following kinds:
                            </li>
                            <ol>
                                <li>
                                    Review and Ratings
                                </li>
                                <li>
                                    Question and Answers
                                </li>
                                <li>
                                    Crowd Source Data Collection (poll questions).
                                </li>
                            </ol>
                            <li>
                                Each User who posts review or ratings, Q&A, photographs shall have a profile.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        APP PERMISSIONS THAT SHOULD BE ALLOWED FOR USING THE HOPE APP:
                    </li>
                    <p className='para-1'>
                        Upon successful installation of HOPE App on a Users’ phone or tablet, the User is provided
                        with a detailed list of permissions required by the App to function and provide its services
                        efficiently and effectively. The permissions can either be allowed or denied but cannot be
                        amended. A User cannot put a limitation or restriction on specific permission, the permission
                        is allowed in full capacity. The permissions that Company requires for its effective functioning
                        and the data that it aims to access, and its use is as below:
                        <ol>
                            <li>
                                <span className='bold'>Device & App history:</span> Company requires Users’ device permission to generate
                                information about its device, such as the OS (operating system) name, the OS version,
                                mobile data network, hardware model, unique device identifier, preferred language,
                                etc. On the basis of these inputs, we intend to optimize your user interface
                                experience, make optimum use of OS specific strengths to drive great in-app
                                experiences by using various components of device's OS, etc.
                            </li>
                            <li>
                                <span className='bold'>Identity:</span> This permission enables us to get information of your account(s) on your’
                                mobile device. We utilize this info to fill in the required fields automatically, thereby
                                enabling you a smooth, quick and typing free experience while placing any order or
                                booking any appointment or while submitting any query to Company.
                            </li>
                            <li>
                                <span className='bold'>Location:</span> Access to this permission is important for Company to efficiently deliver the
                                products ordered or to provide the service providers with correct location of the user
                                to give effect to the services availed by them from the app. When you launch HOPE
                                app to book an appointment or order products, we auto-detect your location so that
                                your city is auto-filled. Sharing your location with HOPE, enables it to provide you an
                                expected delivery date of products on order and live tracking status of concerned
                                professional approaching your registered address for the services requested by you.
                            </li>
                            <li>
                                <span className='bold'>.SMS:</span> Access to your SMS allows us to read the OTP (One Time Password) generated
                                during various stages of using the App. The app automatically reads the OTP generated
                                on your registered mobile number via SMS Retriever APP provided by Google Play
                                Services. This provides the User a seamless experience while logging in or while
                                making payment for purchase of products and/or services. The SMS Retriever App
                                allows us to read only those messages which are received for HOPE. We do not have
                                any access to other messages of the User, thereby making it safe and reliable for the
                                User.
                            </li>
                            <li>
                                <span className='bold'>Phone:</span> The app requires access to make phone calls so that tje User can make phone
                                calls to healthcare professionals assigned for door-step services as requested by the
                                respective User.
                            </li>
                            <li>
                                <span className='bold'>Contacts:</span> If you allow us to access your contacts, it enables us to provide a lot of social
                                features to you such as sharing your status of recovery with your family etc. This
                                information will be stored on our servers and synced from your phone.
                            </li>
                            <li>
                                <span className='bold'>Photo/ Media/ Files:</span> The libraries in the app use these permissions to allow map data
                                to be saved to your phone's external storage, like SD cards. By saving map data locally,
                                your phone doesn't need to re-download the same map data every time you use the
                                app. This provides you a seamless Map based service selection experience, even on
                                low bandwidth network.
                            </li>
                            <li>
                                <span className='bold'>Wi-Fi connection information:</span> When you allow us the permission to detect your WiFi connection, we optimize your experience such as more detailing on maps, better
                                image loading, more healthcare services/professionals to choose from, etc.
                            </li>
                            <li>
                                <span className='bold'>Device ID & Call information:</span> This permission is used to detect your Android ID
                                through which we can uniquely identify users. It also lets us know your contact details
                                using which we pre-populate specific fields to ensure a seamless booking experience.
                            </li>
                            <li>
                                <span className='bold'>Calendar:</span> This permission enables us to put your healthcare plan on your calendar.
                            </li>
                            <li>
                                <span className='bold'>Camera:</span> This permission enables you to use your camera from within the application
                                to upload a new profile picture, upload an image for sharing reviews and also allows
                                you to take videos to upload as video reviews on our application. This permission will
                                also enable you to scan QR codes for easy UPI payments.
                            </li>
                            <li>
                                <span className='bold'>Photo Library:</span> The access to your camera is required to enable you to be able to click,
                                review, and upload your profile photo. Alternately, access to your photo library is
                                required for to enable you to upload an existing photograph as a profile picture.
                            </li>
                            <li>
                                <span className='bold'>Video/Audio:</span> This permission enables you to upload videos on our application or to
                                submit video reviews of your Healthcare treatment plan and thereby providing you
                                with new ways of providing reviews of your experience on our application. The audio
                                permission enables us to ensure that the video content that you upload has a clear
                                audio in its background.
                            </li>
                            <li>
                                <span className='bold'>Phone Number:</span> We need the permission of access to phone number to provide a
                                seamless login experience, to optimize your in-app experience and to enable you to
                                obtain UPI services and to prevent fraud in some cases on our application.
                            </li>
                            <li>
                                <span className='bold'>IMEI/IMSI:</span> This permission enables us to identifying different users uniquely and
                                helps us to prevent frauds on our application.
                            </li>
                            <li>
                                <span className='bold'>Subscription Information:</span> Your subscription information enables us to provide you a
                                seamless experience depending upon your network and to optimize the application's
                                performance.
                            </li>
                            <li>
                                <span className='bold'>SIM Serial Number:</span> This permission enables us to read your sim serial number and
                                helps us in authenticating your mobile number for UPI registration. It helps us to
                                ensure that the mobile number you are using is present on your device that you are
                                using to register for UPI.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        HOW WE PROTECT YOUR PERSONAL INFORMATION?
                    </li>
                    <p className='para-1'>
                        All payments made through the app are secured. This means all Personal Information
                        provided by the User is transferred using TLS (Transport Layer Security) encryption. TLS is a
                        proven coding system that lets your browser automatically encrypt, or scramble, data before
                        you send it to us. Website has stringent security measures in place to protect the loss, misuse,
                        and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our
                        possession we adhere to strict security guidelines, protecting it against unauthorized access.
                    </p>
                    <li className='heading'>
                        NON- CONSENT AND PERMISSION
                    </li>
                    <p className='para-1'>
                        The User may at its sole discretion may choose to not to register with the Platform if they
                        don’t intend to grant the consent to submit any or all Personal Information or deny providing
                        any permissions on the Website as covered above.
                    </p>
                    <li className='heading'>
                        USERS’ RIGHTS AS TO THEIR PERSONAL INFORMATION:
                    </li>
                    <p className='para-1'>
                        The User shall be able to access their own Personal Information stored by us from its user
                        account with Platform. The User may also be able to correct their personal information or
                        delete such information (except some mandatory fields) from their user account directly.
                    </p>
                    <li className='heading'>
                        ELIGIBILITY TO TRANSACT WITH HOPE
                    </li>
                    <p className='para-1'>
                        The User should be at least 18 years of age to enter any transaction directly with Company
                        and to provide consent to the processing of its personal information. The User below the 18
                        years of age is required to enter any transaction through their natural/legal Guardian with
                        Company and to provide consent to the processing of its personal information.
                    </p>
                    <li className='heading'>
                        CHANGES TO THE PRIVACY POLICY
                    </li>
                    <p className='para-1'>
                        Company reserves all the rights to revise and amend this Privacy Policy from time to time to
                        suit various legal, business and customer requirements. HOPE shall duly notify its Users about
                        any revision made in its Privacy Policy.
                    </p>
                    <p className='para-1'>
                        The User may always feel free to submit any concerns or seek clarity in regards this Privacy
                        Policy via email to us at <a href='mailto:support@hopetheapp.com'>support@hopetheapp.com</a>. Company shall respond to all reasonable
                        concerns and inquires received from its Users.
                    </p>
                </ol>
            </p>
        </div>
    )
}

export default HopePrivacyPolicy;