import React from 'react';
import '../Footer/FooterStyle.css';
import Logo from '../../Assets/Logos/HOPE_website_logo_trans.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const footerNavbarData = [
        {
            id: 1,
            name: "About us",
            hashLinkName: "About us",
            hashLink: "About us"
        },
        {
            id: 2,
            name: "Services",
            hashLinkName: "Services",
            hashLink: "Services"
        },
        {
            id: 3,
            name: "Solution",
            hashLinkName: "Solution",
            hashLink: "Solution"
        },
        {
            id: 4,
            name: "Pricing",
            hashLinkName: "Pricing",
            hashLink: "Pricing"
        },
        {
            id: 5,
            name: "Contact us",
            hashLinkName: "Contact",
            hashLink: "Contact"
        },
    ];

    const handlePrivacyPolicyClick = () => {
        navigate('/privacypolicy');
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    }

    const handleTermsAndConditionsClick = () => {
        navigate("/termsandconditions");
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }


    const scrollToSectionWithOffset = (sectionId, offset = 0) => {
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
            const offsetTop = targetElement.offsetTop;
            window.scrollTo({
                top: offsetTop - offset,
                behavior: 'smooth',
            });
        }
    };

    const SocialMediaIcons = [
        {
            id: 1,
            Icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M10.5001 12.5509C11.8864 12.5509 13.0103 11.4271 13.0103 10.0407C13.0103 8.65437 11.8864 7.53052 10.5001 7.53052C9.11372 7.53052 7.98987 8.65437 7.98987 10.0407C7.98987 11.4271 9.11372 12.5509 10.5001 12.5509Z" fill="white"/>
                    <path d="M13.9516 2.19653H7.0485C5.88384 2.19785 4.76727 2.66109 3.94373 3.48463C3.1202 4.30816 2.65696 5.42474 2.65564 6.58939V13.4925C2.65696 14.6571 3.1202 15.7737 3.94373 16.5972C4.76727 17.4207 5.88384 17.884 7.0485 17.8853H13.9516C15.1162 17.884 16.2328 17.4207 17.0563 16.5972C17.8799 15.7737 18.3431 14.6571 18.3444 13.4925V6.58939C18.3431 5.42474 17.8799 4.30816 17.0563 3.48463C16.2328 2.66109 15.1162 2.19785 13.9516 2.19653ZM10.5 13.8062C9.75532 13.8062 9.02734 13.5854 8.40814 13.1717C7.78893 12.7579 7.30633 12.1699 7.02134 11.4818C6.73635 10.7938 6.66179 10.0367 6.80707 9.30635C6.95236 8.57595 7.31097 7.90504 7.83755 7.37845C8.36414 6.85186 9.03505 6.49325 9.76545 6.34796C10.4959 6.20268 11.2529 6.27725 11.9409 6.56223C12.629 6.84722 13.217 7.32983 13.6308 7.94903C14.0445 8.56823 14.2653 9.29621 14.2653 10.0409C14.2642 11.0392 13.8671 11.9963 13.1613 12.7021C12.4554 13.408 11.4983 13.8051 10.5 13.8062ZM14.5791 6.90317C14.3929 6.90317 14.2109 6.84796 14.0561 6.74452C13.9013 6.64109 13.7807 6.49407 13.7094 6.32207C13.6382 6.15006 13.6195 5.96079 13.6559 5.7782C13.6922 5.5956 13.7818 5.42787 13.9135 5.29622C14.0451 5.16457 14.2129 5.07492 14.3955 5.0386C14.5781 5.00228 14.7673 5.02092 14.9393 5.09217C15.1113 5.16341 15.2584 5.28407 15.3618 5.43887C15.4652 5.59367 15.5204 5.77566 15.5204 5.96184C15.5204 6.21149 15.4213 6.45092 15.2447 6.62746C15.0682 6.80399 14.8288 6.90317 14.5791 6.90317Z" fill="white"/>
                    </svg>`,
            hashLink: "https://www.instagram.com/"
        },
        {
            id: 2,
            Icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M10.2296 1.88257C8.12025 1.88261 6.09292 2.69962 4.57298 4.16216C3.05305 5.62471 2.15865 7.6191 2.07746 9.72687C1.99628 11.8346 2.73461 13.8919 4.13754 15.467C5.54047 17.0422 7.49895 18.0127 9.60202 18.175V11.9235H7.71937C7.55293 11.9235 7.39331 11.8573 7.27562 11.7397C7.15793 11.622 7.09182 11.4623 7.09182 11.2959C7.09182 11.1295 7.15793 10.9699 7.27562 10.8522C7.39331 10.7345 7.55293 10.6684 7.71937 10.6684H9.60202V8.7857C9.60278 8.12019 9.86749 7.48215 10.3381 7.01156C10.8087 6.54097 11.4467 6.27626 12.1122 6.2755H13.3673C13.5338 6.2755 13.6934 6.34162 13.8111 6.45931C13.9288 6.57699 13.9949 6.73661 13.9949 6.90305C13.9949 7.06949 13.9288 7.22911 13.8111 7.3468C13.6934 7.46448 13.5338 7.5306 13.3673 7.5306H12.1122C11.7795 7.53098 11.4605 7.66334 11.2252 7.89863C10.9899 8.13393 10.8575 8.45295 10.8571 8.7857V10.6684H12.7398C12.9062 10.6684 13.0658 10.7345 13.1835 10.8522C13.3012 10.9699 13.3673 11.1295 13.3673 11.2959C13.3673 11.4623 13.3012 11.622 13.1835 11.7397C13.0658 11.8573 12.9062 11.9235 12.7398 11.9235H10.8571V18.175C12.9602 18.0127 14.9187 17.0422 16.3216 15.467C17.7245 13.8919 18.4628 11.8346 18.3817 9.72687C18.3005 7.61911 17.4061 5.62472 15.8861 4.16217C14.3662 2.69963 12.3389 1.88261 10.2296 1.88257Z" fill="white"/>
                    </svg>`,
            hashLink: "https://www.facebook.com/login/"
        },
        {
            id: 3,
            Icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path d="M20.1886 6.09168L17.8224 8.4579C17.35 13.9396 12.7271 18.199 7.19383 18.199C6.05448 18.199 5.11553 18.0183 4.40298 17.662C3.82795 17.3745 3.59238 17.0659 3.53393 16.9782C3.4815 16.8995 3.44751 16.81 3.4345 16.7164C3.4215 16.6228 3.42981 16.5274 3.45883 16.4374C3.48785 16.3475 3.53682 16.2652 3.60208 16.1968C3.66734 16.1284 3.74721 16.0757 3.83572 16.0425C3.85591 16.0349 5.70646 15.3241 6.9025 13.9709C6.15856 13.4411 5.5038 12.7962 4.96285 12.0604C3.88755 10.6011 2.75027 8.06672 3.43864 4.28058C3.45913 4.1679 3.51006 4.06298 3.58591 3.97718C3.66177 3.89138 3.75966 3.82797 3.86898 3.79382C3.97829 3.75968 4.09487 3.7561 4.20607 3.78347C4.31728 3.81084 4.41887 3.86812 4.49985 3.9491C4.52751 3.97668 7.13887 6.55116 10.3312 7.38275L10.3316 6.90274C10.3383 5.89798 10.7438 4.93701 11.4588 4.23106C12.1738 3.52512 13.1399 3.13196 14.1446 3.13801C14.7973 3.14705 15.4366 3.32523 15.9999 3.65515C16.5631 3.98508 17.0312 4.45546 17.3584 5.02035L19.7449 5.02039C19.869 5.02039 19.9903 5.0572 20.0935 5.12615C20.1967 5.19511 20.2771 5.29311 20.3246 5.40778C20.3721 5.52245 20.3846 5.64863 20.3604 5.77036C20.3362 5.89209 20.2764 6.00391 20.1886 6.09168Z" fill="white"/>
                    </svg>`,
            hashLink: "https://twitter.com/login?lang=en"
        },
    ];


    return (
        <div className="footer_Screen_Container">
            <div className="footer_inner_container">
                <div className="upper_container">
                    <div className="left_container">
                        <img src={Logo} className='footerlogo' />
                    </div>
                    <div className="footer_navbar_list">
                        <ul className='Navbar_List' >
                            {footerNavbarData.map((item) => (
                                <li key={item.id}>
                                    <a onClick={() => scrollToSectionWithOffset(item.hashLinkName, 100)}
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="lower_container">
                    <div className="lower_left_container">
                        <div className="copyrights_container">
                            <p>Solutize Healthcare Private Limited. All rights Reserved.</p>
                        </div>
                        <div className="terms_condition_container">
                            <ul>
                                <li>
                                    <a onClick={handleTermsAndConditionsClick}>
                                        Terms and Conditions
                                    </a>
                                    <a onClick={handlePrivacyPolicyClick}>
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="social_icons_container">
                        <ul>
                            {SocialMediaIcons.map((item) => (
                                <li key={item.id}>
                                    <a href={item.hashLink} target='_blank' rel='noopener noreferrer'>
                                        <div dangerouslySetInnerHTML={{ __html: item.Icon }} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="mobile_container">
                    <img src={Logo} className='footerlogo' />
                    <div className="social_icons_container">
                        <ul>
                            {SocialMediaIcons.map((item) => (
                                <li key={item.id}>
                                    <a href={item.hashLink} target='_blank' rel='noopener noreferrer'>
                                        <div dangerouslySetInnerHTML={{ __html: item.Icon }} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="terms_condition_container">
                        <ul>
                            <li>
                                <a onClick={handleTermsAndConditionsClick}>
                                    Terms and Conditions
                                </a>
                                <a onClick={handlePrivacyPolicyClick}>
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="copyrights_container">
                        <p>© HOPE Ltd. All rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;