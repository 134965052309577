import React from 'react';
import './HopeBuddyTermsAndConditions.css';

const HopeBuddyTermsAndConditions = () => {

    return (
        <div className='hope-buddy-terms-and-conditions-main-container'>
            <div className={`title-container`}>
                <p className={`title`}>TERMS AND CONDITIONS</p>
            </div>
            <p className='para'>
                <span className='bold'>
                    THIS IS A LEGAL AGREEMENT OF THE TERMS OF USE BETWEEN SOLUTIZE HEALTHCARE
                    PRIVATE LIMITED (HEREINAFTER REFERRED TO AS WE, US, OUR, OR COMPANY AND
                    WHICH EXPRESSION SHALL MEAN AND INCLUDES AFFILIATES, SUCCESSORS AND
                    PERMITTED ASSIGNS) AND YOU , AN INDEPNDENT THIRDPARTY SERVICE PROVIDER , AN
                    INDIVIDUAL OR LEGAL ENTITY OR ASSOCIATION (HEREINAFTER REFERRED TO AS YOU OR
                    YOUR OR SERVICE PROVIDER AND WHICH EXPRESSION SHALL MEAN AND INCLUDES AN
                    INDIVIDUAL OR LEGAL ENTITY OR ASSOCIATION ITS AFFILIATES, SUCCESSORS AND
                    PERMITTED ASSIGN) . BY REGISTERING AS SERVICES PROVIDER OR, BROWSING OR BY
                    USING THE SERVICES OF THE PLATFORM YOU UNCONDITIONALLY AND IRREVOCABLY
                    REPRESENT, WARRANT, AND AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE
                    TO BE BOUND TO THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS,
                    OR YOU DO NOT HAVE THE AUTHORITY TO BIND TO THE AGREEMENT, YOU MAY NOT
                    REGISTER OR USE THE SERVICES OR ACCESS THE PLATFORM. OUR PRIVACY POLICY CAN
                    BE FOUND HERE www.hopetheapp.com. THESE TERMS AND OUR PRIVACY POLICY
                    CONSTITUTE A BINDING CONTRACT ON YOU AND GOVERNS YOUR USE OF AND ACCESS
                    TO THE SERVICES OR PLATFORM BY YOU, YOUR AGENTS, AND USER. PLEASE READ IT
                    CAREFULLY.
                </span>
            </p>
            <p className='para'>
                <span className='bold'>
                    This document is published in accordance with the provisions of Rule 3 (1) of the
                    Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing
                    the rules and regulations, privacy policy and Terms of Use for access or usage of the
                    platform defined herein below.
                </span>
            </p>
            <p className='para'>
                www.hopetheapp.com and the mobile application ‘HOPE BUDDY’ (together referred to as
                <span className='bold'>“PLATFORM”</span>) is owned and operated by <span className='bold'>SOLUTIZE HEALTHCARE PRIVATE LIMITED</span> having
                its registered office currently located at C-4504 DB Woods, Krishna Vatika Marg,
                Gokuldham, Goregaon East, Mumbai, Maharashtra, India, 400063
            </p>
            <p className='para'>
                The Company provides an interface that enable qualified and skilled professionals to offer
                their services to the usersregistered with the Company under other affiliated platform duly
                owned by the company including but not limited to www.hopetheapp.com and mobile
                application HOPE APP (www.Hopetheapp.com and HOPE APP collectively referred to as
                “Primary Platform”), where Our matchmaking algorithm identify service provider that are
                closest to the user's needs and available at the requested time and date. <span className='bold'>(Said Purpose)</span>
            </p>

            <p className='para'>
                <ol>
                    <li className='heading'>
                        DEFINITIONS
                    </li>
                    <p className='para-1'>
                        For the purposes of this Agreement, in addition to what is stated herein, the following
                        terms shall have the meaning as set forth below unless otherwise specified herein:
                    </p>
                    <p className='para-1'>
                        <ol className='abcd'>
                            <li>
                                <span className='bold'>Platform:</span> The platform includes www.hopetheapp.com and the mobile application
                                ‘HOPE BUDDY’, It also includes browser extensions, the queries answered by the Hope
                                team over email, phone or another medium.
                            </li>
                            <li>
                                <span className='bold'>User -</span> The term user includes a person/institution browsing the website or registered on
                                the app, a trial user (if any), end-user (subscription-based) and everyone who maybe
                                using the platform and/or availing services duly defined herein. This includes, but not
                                limited to, Medical/Health Service Providers.<br /><br />
                                <span className='bold'>Medical / Healthcare service provider(Provider) :</span> The term medical or healthcare service
                                provider includes whether an individual professional or an organization or similar
                                institution wishing to be listed, or already listed, on the panel of the company, including
                                designated, authorized associates of such providers or institutions to render services to
                                the users duly registered under primary platform. Medical /or Healthcare service
                                provider(Provider) shall include but not limited to certified nursing professionals duly
                                registered under the Indian Nursing Council Act, 1947.<br />
                                <span className='bold'>Patient:</span> Patient means any individual who have is registered (end user) on the Primary
                                platform, by following all procedures, for the purpose of managing their own health and
                                desirous of availing services offered by the primary platform
                            </li>
                            <li>
                                <span className='bold'>Caregiver:</span> Caregiver means persons who are authorised representatives of Patient who
                                will be managing membership on his/her behalf which shall include family/relatives, close
                                friends, neighbors.
                            </li>
                            <li>
                                <span className='bold'>Service:</span> We enable healthcare service provider to connect with Patients and/or caregiver
                                for their healthcare requirements (as prescribed by their own medical Practitioner)
                                through this platform by using our proprietary algorithm where Patients and/or caregiver
                                can have access and connect to various to provider and vice-versa. This includes
                                providing home-based nursing as the core service provided by the platform, specifically
                                for Cancer patients, in Mumbai and surrounding regions and/or any other kind of services
                                provided through the platform including (i) Plan and track their treatment effectively, (ii)
                                Stay connected with Patients and/or caregivers and coordinate activities with them, (iii)
                                Schedule an appointments (in person or virtual)(iv) Provide for home-based services, (v)
                                Get reminders for important activities, (vi) Digital documentation of Healthcare Service
                                Provider-Patient’s records, visits, terms of the Service availed, bills , etc., ,(viii)
                                contributing through article section for building patient awareness and empowerment
                                via our platform.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        APPLICABLE LAWS
                    </li>
                    <p className='para-1'>
                        This Platform is created and controlled by the Company in India; as such the laws
                        of India shall apply; courts in Mumbai, India shall alone have exclusive jurisdiction
                        (subject to Arbitration at Mumbai, India) in respect of all the terms, conditions and
                        disclaimers. The Company exclusively reserves the right to make the changes to the
                        site and the terms, conditions, Privacy Policy and disclaimers thereof. The User is
                        deemed to have been entered into at India and the laws of India will govern this
                        Agreement. Any dispute, claim or controversy arising out of or relating to this
                        Agreement, including the determination of the scope or applicability of this
                        Agreement to arbitrate, or your use of the platform or the Services or information
                        to which it gives access, as also including interpretation of any of the terms shall be
                        referred to Arbitration in Mumbai, India under the Arbitration and Conciliation Act
                        (Indian Act), 1996 before a Sole Arbitrator. The seat of such arbitration shall be
                        Mumbai. All proceedings of such arbitration, including, without limitation, any
                        awards, shall be in the English language. The award shall be final and binding on the
                        parties to the dispute.
                    </p>
                    <li className='heading'>
                        ELIGIBILITY
                    </li>
                    <p className='para-1'>
                        You represent and warrant that you are competent and eligible to enter into a legally
                        binding agreement and have the requisite authority to bind the other party to this
                        Agreement. You shall not use this Service if you are not competent to contract under the
                        applicable Indian Laws, rules, and regulations. By registering on Platform, you explicitly
                        confirm that:
                        <ul>
                            <li>
                                You are above 18 years and above.
                            </li>
                            <li>
                                You are not disabled by any law from entering into a contract.
                            </li>
                            <li>
                                You have gone through the Terms and Conditions and agree to be bound to
                                them.
                            </li>
                            <li>
                                You are qualified and registered under respective statute, if any applicable
                                including but not limited in respect to certified nursing professionals duly
                                registered under the Indian Nursing Council Act, 1947.
                            </li>
                        </ul>
                    </p>
                    <p className='para-1'>
                        If you wish to connect to the Patients and/or Caregiver and makes use of the Platform,
                        read these Terms of Use and follow the instructions in the registration process. By using
                        the Platform, you consent to submit your personal identifiable information, to collect,
                        process and display and use the said information to access the Platform.
                    </p>
                    <li className='heading'>
                        ACCOUNT CREATION
                    </li>
                    <p className='para-1'>
                        <ol className='abcd'>
                            <li>
                                You will need to register for an account on the Platform to access and utilize the
                                Services ("Account"). You will be asked to provide certain information and
                                supporting documentation, such as your name, contact information, residence,
                                age, and proof of identity from the government, trade licenses, and legal
                                permissions allowing you to provide services.
                            </li>
                            <li>
                                You must be at least eighteen (18) years old to create an account.
                            </li>
                            <li>
                                You will always be liable to maintain such amount as agreed as minimum
                                refundable security Deposit amount , in the event of deficiency your account can
                                be suspended until same is made good. You hereby agree and confirm that
                                refundable security deposit shall be subject to deductions under this agreement.
                            </li>
                            <li>
                                You warrant that any data you have provided in relation to your account is truthful
                                and accurate, and you will continue to do so. In the event that this information
                                changes or is modified, you commit to updating your information on the Platform
                                as soon as possible
                            </li>
                            <li>
                                You hereby confirm and give consent in the event Company is using third parties
                                to verify your background and satisfy safety and due diligence requirements then
                                in that case you shall fully cooperate and same shall be done at your own expense.
                                This will happen before your Platform account is approved, your access to the
                                Services is activated. Notwithstanding anything mentioned herein whether
                                verification is done or not you all always be liable for all acts, deed and thing
                                undertake and performed by you and shall keep company indemnified for losses,
                                liquidated damages, cost and claim arising on account of it.
                            </li>
                            <li>
                                You may only own, operate, and possess one Account. If we discover or
                                determines that you possess more than one Account, we will have the right to
                                revoke, without notice, your access to the Platform and the Services.
                            </li>
                            <li>
                                You promise to promptly notify us of any disclosure, unauthorised use, or other
                                security breach pertaining to your account. You are solely responsible for
                                protecting the security and confidentiality of your login credentials. You also agree
                                that no one else has access to your account but yourself and that you won't give
                                out your login information to anybody else. We may suspend or terminate your
                                access to and use of the Services immediately without prior notice if we
                                reasonably conclude that you have given someone else access to and use of your
                                Account or that you have shared your login credentials with someone else.
                            </li>
                            <li>
                                Any activity that occurs through your Account is your responsibility and you are
                                liable for it.
                            </li>
                            <li>
                                You agree to receive communications from us regarding (i) information about us
                                and the Services, (ii) promotional offers and services from us and our third-party
                                partners, and (iii) any other matter in relation to the Services.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        GENERAL TERMS
                    </li>
                    <p className='para-1'>
                        <ul className='none'>
                            <li>
                                <span className='bold'>5.1</span> By downloading or accessing the Platform for the purpose to use the Services, you irrevocably
                                accept all the conditions stipulated in this Agreement, the Subscription Terms of
                                Service and Privacy Policy, as available on the Platform and agree to abide by them. Unless
                                a mutually executed agreement between you and us (if any) states otherwise, the present
                                Agreement supersedes all prior oral and written terms and conditions (if any) communicated
                                to you relating to your use of the Platform to avail the Services. By availing any Service, you
                                signify your acceptance of the terms of this Agreement.
                            </li>
                            <li>
                                <span className='bold'>5.2</span> We shall have sole discretionary power to change the Service and may change the service
                                from time to time, and the present Agreement will be applicable to your access to and your
                                use of the Platform for the purpose of availing the Service, as well as to all information
                                provided by you on the Platform at any given point in time.
                            </li>
                            <li>
                                <span className='bold'>5.3</span> We reserve the right to modify, revoke or amend or update any Terms of the present
                                Agreement for any reason and at any time, and such modifications shall be notified to you as
                                an alert on the platform. You should read the Agreement at regular intervals. Your use of the
                                Platform following any such modification constitutes your agreement to follow and be bound
                                by the Agreement so modified.
                            </li>
                            <li>
                                <span className='bold'>5.4</span> You acknowledge that you will be bound by this Agreement for availing any of the Services
                                offered by us. If you do not agree with any part of the Agreement, please do not use the
                                Platform or avail any Services.
                            </li>
                            <li>
                                <span className='bold'>5.5</span> Your access to use of the Platform and the Services will be solely at the discretion of the
                                Company.
                            </li>
                            <li>
                                <span className='bold'>5.6</span> The Agreement is published in compliance of, and is governed by the provisions of Indian
                                law, including but not limited to:
                            </li>
                            <ol>
                                <li>
                                    the Indian Contract Act, 1872,
                                </li>
                                <li>
                                    the (Indian) Information Technology Act, 2000, and
                                </li>
                                <li>
                                    the rules, regulations, guidelines, and clarifications framed there under, including
                                    the (Indian) Information Technology (Reasonable Security Practices and
                                    Procedures and Sensitive Personal Information) Rules, 2011 (the <span className='bold'>“SPDI Rules”</span>),
                                    and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011
                                    (the <span className='bold'>“IG Rules”</span>).
                                </li>
                            </ol>
                            <li>
                                <span className='bold' style={{ textDecoration: 'underline' }}>5.7 PROVIDER ACCOUNT AND DATA PRIVACY</span>
                            </li>
                            <ol style={{ listStyleType: 'none' }}>
                                <li>
                                    5.7.1 The terms “personal information” and “sensitive personal data or information”
                                    are defined under the SPDI Rules and are reproduced in the Privacy Policy.
                                </li>
                                <li>
                                    5.7.2 Users acknowledge that we may collect information about the devices you use to
                                    access the platform and anonymous usage data solely for the purpose of
                                    enhancing the quality of the provided Service and developing new services.
                                </li>
                                <li>
                                    5.7.3 It is understood by You that, for the purpose of communication and with the intent
                                    to provide Patients and care giver a convenience to schedule appointments, The
                                    you allow us to share your personal contact details such as email or phone
                                    number,.
                                </li>
                                <li>
                                    5.7.4 The Privacy Policy sets out, <i>inter-alia</i>:
                                </li>
                                <ol>
                                    <li>
                                        The kind of information gathered from Users , inclusive of sensitive
                                        personal data or information;
                                    </li>
                                    <li>
                                        The purpose, means and modes of usage of such information;
                                    </li>
                                    <li>
                                        How and to whom HOPE will disclose such information; and,
                                    </li>
                                    <li>
                                        Other information mandated by the SPDI Rules.
                                    </li>
                                </ol>
                                <li>
                                    5.7.5 The User is expected to read and understand the Privacy Policy, so as to ensure
                                    that he or she has the knowledge of, <i>inter-alia</i>:
                                </li>
                                <ol>
                                    <li>
                                        The fact that certain information is being collected;
                                    </li>
                                    <li>
                                        The purpose behind the gathering of information;
                                    </li>
                                    <li>
                                        The intended recipients of the information;
                                    </li>
                                    <li>
                                        The nature of gathering and retention of the information; and
                                    </li>
                                    <li>
                                        The name and address of the agency that is collecting the information and the agency that will retain the information; and
                                    </li>
                                    <li>
                                        The various rights available to such Users in respect of such information.
                                    </li>
                                </ol>
                            </ol>
                        </ul>
                    </p>
                    <li className='heading'>
                        TERMS OF USE APPLICABLE TO ALL MEDICAL OR HEALTHCARE PROVIDERS
                    </li>
                    <p className='para-1'>
                        <ul className='none'>
                            <li>
                                6.1 The Provider shall explain in detail the terms of the service to the Patient.
                            </li>
                            <li>
                                6.2 The Providers understands and agrees that they shall be solely liable to render the service to
                                the Patients as may be prescribed by their medical practitioner and to the best of their
                                knowledge.
                            </li>
                            <li>
                                6.3 The Provider understands and agrees that he/she shall be responsible to handle the patients
                                with utmost care take all the precautions and shall not engage themselves in to (i) to
                                prescribe, save and except Doctors or such certified professional duly permitted under the law, (ii)
                                solicit any drug, medicine or any medical treatment that the Provider is not qualified to
                                suggest or prescribe. In case of any negligence the Provider shall be solely responsible to bear
                                the consequences. The Provider indemnify and keep platform indemnify against any such
                                claims, liabilities, cost, damages, penalties and consequences that may arise due to the
                                violation of any of the governing laws of the land.
                            </li>
                        </ul>
                    </p>
                    <li className='heading'>
                        APPOINTMENT SCHEDULING FACILITY:
                    </li>
                    <p className='para-1'>
                        <ul className='none'>
                            <li>
                                7.1 We enable Providers to connect with patients and/or caregivers for healthcare service and
                                to provide Professional service for home-based service.
                            </li>
                            <li>
                                7.2 We will enable to close appointment on the Appointment Scheduling facility. However, we
                                Shall not be liable for the non-availability of the patient/caregiver or if they subsequently
                                cancel appointment or
                            </li>
                            <li>
                                7.3 If a patient or caregiver has availed the telephonic services, we reserve the right to store
                                such information and/or conversation of the patient/caregiver with the Provider, in
                                accordance with our Privacy Policy.
                            </li>
                            <li>
                                7.4 You should not endorse any such Particular Service which is not part of his/her scope.
                            </li>
                            <li>
                                7.5 Without prejudice to the generality of the above, we are not involved in providing any
                                healthcare or medical advice and hence shall not be liable for any Communication between
                                Provider and Patient/caregiver. Provider understands and agrees that we will not be liable
                                for:
                            </li>
                            <ol>
                                <li>
                                    any communication and associated issues Patient/caregiver have with the
                                    you;
                                </li>
                                <li>
                                    the ability or intent of the Provider(s) or the lack of it, in fulfilling their
                                    obligations towards Patients/caregiver ;
                                </li>
                                <li>
                                    any wrong medication or quality of treatment being given by the Provider(s),
                                    or any medical negligence on part of the Provider(s);
                                </li>
                                <li>
                                    inappropriate treatment, or similar difficulties or any type of inconvenience
                                    suffered by the User due to a failure on the part of the Provider to provide
                                    agreed Services;
                                </li>
                                <li>
                                    any misconduct or inappropriate behaviour by the Provider or the Provider’s
                                    staff;
                                </li>
                                <li>
                                    Cancellation or non-availability of the Provider or rescheduling of booked
                                    appointment or if any variation in the fees charged.
                                </li>
                            </ol>
                        </ul>
                    </p>
                    <li className='heading'>
                        NO CALL-NO-SHOW (N.C.N.S)
                    </li>
                    <p className='para-1'>
                        In case of a ‘No Call-No-Show (N.C.N.S)’ (defined below), where the Provider does not show-up.
                        <ol className='abcd'>
                            <li>
                                No Call-No-Show (N.C.N.S) by the provider for the purposes of these Terms and
                                Conditions, is defined as, any instance where a Provider, confirmed booking of
                                an appointment on the platform using the Appointment Scheduling facility failed
                                to attend Patient/caregiver without cancelling, rescheduling, or informing the
                                Company and patient/caregiver 3 <u><span className='bold'>working</span></u> days in advance;
                            </li>
                            <li>
                                In the event of No Call-No-Show (N.C.N.S) as duly defined herein above provider
                                shall be subject to penalty equivalent to appointment charges which one needs
                                to pay within 3 working days in the event of failure to do said penalty shall be
                                deducted from your security deposit and your access to the platform shall be
                                restricted and you shall not be able to use the services subscribed for.;
                            </li>
                            <li>
                                We reserve a right to terminate or suspend or deactivate provider account upon
                                2 instances of N.C.N.S in span of 30 days.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        PAYMENT TERMS
                    </li>
                    <p className='para-1'>
                        <ul className='none'>
                            <li>
                                9.1 You hereby confirms and agrees that all payment against services rendered by the
                                provider to the patient/caregiver shall be routed through company after deduction of
                                commission on each transaction within such working days as agreed.
                            </li>
                            <li>
                                9.2 All payments shall be done in the designated account , details of which would have
                                been shared while creating account.
                            </li>
                            <li>
                                9.3 Company reserves right to have different commercial arrangement with different
                                category of provider as it may deem fit and proper , which you hereby consent with.
                            </li>
                        </ul>
                    </p>
                    <li className='heading'>
                        CONDUCT
                    </li>
                    <p className='para-1'>
                        <ol className='abcd'>
                            <li>
                                We strictly prohibit discrimination against Patients/caregivers on the basis of race,
                                religion, caste, national origin, disability, sexual orientation, sex, marital status, gender identity,
                                age, or any other characteristic that may be protected under applicable law. Such discrimination
                                includes but is not limited to any refusal to provide the services based on any of these
                                characteristics.
                            </li>
                            <li>
                                you shall treat all patients/caregivers with courtesy and respect. We reserve the
                                right to withhold access to the Services at our absolute discretion if you behave towards any
                                Patient/caregiver in a manner which is discourteous, disrespectful, abusive, or which we
                                otherwise deem to be inappropriate or unlawful.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        USER CONTENT ON PRIMARY PLATFORM
                    </li>
                    <p className='para-1'>
                        <ol className='abcd'>
                            <li>
                                Our Primary Platform may contain interactive features or services that allow users
                                who have created an account or profile with us to post, upload, publish, display, transmit, or
                                submit comments, reviews, suggestions, feedback, ideas, or other content on or through the
                                Platform ("User Content").
                            </li>
                            <li>
                                As part of the effective provision of the Services and quality control purposes, we
                                may request reviews from you about Patients/caregivers, and you agree and acknowledge that
                                Patients/caregivers may provide reviews about you to us. You must not knowingly provide false,
                                inaccurate, or misleading information in respect of the reviews. Reviews will be used by us for
                                quality control purposes, to determine whether Customers and Service Professionals are
                                appropriate users of the Platform. If we determine at our sole discretion that you are not an
                                appropriate user, we reserve the right to cancel your registration. We may at any time modify
                                the terms and conditions (“TERMS”) of the Service without any prior notice or intimation to you.
                                You can access the latest version of the Terms at any given time. You should regularly review the
                                Terms and you are expected to do so at regular intervals. In the event, the modified Terms are
                                not acceptable to you; you should discontinue using the services. However, if you continue to
                                avail the Services provided by the us you irrevocably and unconditionally agree to accept and
                                abide by the modified Terms.
                            </li>
                            <li>
                                You shall be always subject terms and condition of primary platform including but not
                                limited to terms and conditions pertaining to contents.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        CONFIDENTIALITY
                    </li>
                    <p className='para-1'>
                        The parties acknowledge that they shall receive information belonging to the other party that can
                        be reasonably construed to be confidential and privileged information not intended to be
                        disclosed to any third parties or another party unless it needs to be disclosed for the fulfilment
                        of these Terms. The parties agree to use reasonable standards of care to keep such information
                        confidential. Nothing contained herein shall affect the ability of the parties to make disclosures
                        mandated by applicable laws.
                    </p>
                    <p className='para-1'>
                        you release and indemnify us and/or all any of our officers and representatives from any claims
                        as to coat, damage, liability or other consequence of any of the actions of the Users and Providers
                        of the HOPE BUDDY APP and specifically waive any claims that you may have in this behalf under
                        any applicable law by which this Agreement is governed. Notwithstanding our reasonable efforts
                        in that behalf, we cannot control the information provided by other User or patient or care giver
                        . In the event you find other User’s or patients or caregiver information to be offensive, harmful,
                        inaccurate, false or deceptive. Please use caution, common sense and safe practice while using
                        the Platform. Please note that there are also risks of dealing with unwanted and wrong people
                        as also with people of unsocial elements. You may report any context which you may find
                        offensive by writing to us at <a href='mailto:support@hopetheapp.com'>support@hopetheapp.com</a> and we shall verify and take necessary
                        action as we may deem fit in our sole discretion.
                    </p>
                    <li className='heading'>
                        OTHER TERMS OF USE
                    </li>
                    <p className='para-1'>
                        The other terms of use for the Platform are:
                        <ol>
                            <li>
                                User agrees by using this platform that any information shared by you with us or with any
                                Provider will be subject to our Privacy Policy.
                            </li>
                            <li>
                                We reserve the right in its sole discretion to review the activity and status of each account
                                and block access to the user based on such review.
                            </li>
                            <li>
                                We reserve the right at its sole discretion to restrict/suspend your access to the Platform
                                based on review of the user's activity, status or any such criteria we may deems fit and
                                proper with due intimation to you.
                            </li>
                            <li>
                                You hereby confirm that as on date of this registration, you do not have any objection to
                                receive emails, SMS/WhatsApp messages and calls from us and users of the HOPE BUDDY
                                as long as you are a registered user of the Platform including SMS permission for
                                authenticating mobile verification wand transactions via OTP, sent by the Payment
                                Gateway. This consent shall supersede any preferences set by you with or registration is
                                done with the Do Not Disturb (DND Register)/ National Customer Preference Register
                                (NCPR). This consent extends to emails, messages or calls relating but not limited to phone
                                number verification, the provision for advertising service,
                            </li>
                            <li>
                                Multiple profiles of the users are not allowed on our Site. We reserve the right to deactivate
                                all multiple profiles without any refund of subscription fees.
                            </li>
                            <li>
                                The content that User’s chosen to submit for publication on the platform, including any
                                feedback, ratings, or reviews (“Critical Articles”) relating to Providers or other healthcare
                                professionals, is user’ssole responsibility. Our role in publishing Critical content is restricted
                                to that of an ‘intermediary’ under the Information Technology Act, 2000. we disclaim all
                                responsibility with respect to the content of Critical content, and its role with respect to
                                such content is restricted to its obligations as an ‘intermediary’ under the said Act. We shall
                                not be liable to pay any consideration to any User for re-publishing any content across any
                                of its platforms.
                            </li>
                            <li>
                                The provisions of Clause 11 of these Terms apply to your publication of reviews and
                                comments on the APP. Without prejudice to the detailed terms stated in Clause 11, you
                                hereby agree not to post or publish any content on the APP that (a) infringes any third-party
                                intellectual property or publicity or privacy rights, or (b) violates any applicable law or
                                regulation, including but not limited to the IG Rules and SPDI Rules. HOPE BUDDY, at its sole
                                discretion, may decide not to publish your reviews and feedback, if so required by applicable
                                law, as per Clause 11 of these Terms. You agree that HOPE BUDDY may contact you through
                                telephone, email, SMS, or any other electronic means of communication for all the purposes
                                within terms and conditions herein.
                            </li>
                            <li>
                                Except for the purpose of promoting/advertising your profile for matchmaking purposes,
                                you cannot engage in advertising to, or solicitation of, other User to avail your services.
                            </li>
                            <li>
                                Any person who visits the Platform are classified by HOPE BUDDY as User with complete
                                profiles. HOPE BUDDY reserves the absolute right to modify, amend, change, curtail or
                                expand any privileges, rights, benefits, liabilities, or responsibilities of Users as it deems
                                fit, and these will be instantly applicable to all past, present and future Users. The
                                quantum of these benefits and even the benefits in totality will be at the sole discretion
                                of the HOPE BUDDY. HOPE BUDDY will determine at its own discretion how many benefits
                                it offers to every user.
                            </li>
                            <li>
                                Any profile deleted by the user cannot be retrieved under any circumstances.
                            </li>
                            <li>
                                We do housekeeping of profiles that are not logged in for a period of 12 months. We
                                remove all the data associated with the profile during housekeeping.
                            </li>
                            <li>
                                This Agreement, accepted upon use of the Site and further affirmed by becoming a User
                                of the HOPE BUDDY service, contains the entire agreement between you and HOPE
                                BUDDY regarding the use of the Site and/or the Service. If any provision of this Agreement
                                is held invalid, the remainder of this Agreement shall continue in full force and effect.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        LINK TO OTHER SITES
                    </li>
                    <p className='para-1'>
                        The Platform may contain links to other sites and may be governed by respective terms of
                        use not covered herein. Accessing or using these sites shall be at your discretion. We have
                        no control over, and not liable or under obligation for Articles, accuracy, validity, reliability,
                        quality of other sites or made available by/through third platform. We do not take
                        responsibility for any such links to Third Party websites, affiliates or business partners.
                        Inclusion of any link on the APP does not imply that HOPE BUDDY endorses the linked site.
                        User may use the links and these services at User’s own risk.
                    </p>
                    <li className='heading'>
                        CORPORATE PARTNERS
                    </li>
                    <p className='para-1'>
                        We reserve the right to tie up with partners in future to come up with various other online
                        services. We also reserve the right to use/share the information/data collected from Users
                        with its partners if a User’s has already made public the information / data to Users of the
                        Platform.
                    </p>
                    <p className='para-1'>
                        We are not responsible for the conduct of its corporate partners. Any disputes arising
                        between registered Users and the respective corporate partner post availing corporate
                        credits will not be resolved by us.
                    </p>
                    <li className='heading'>
                        INTELLECTUAL PROPERTY
                    </li>
                    <p className='para-1'>
                        These Terms grants no implied license, right or interest in or to any copyright, patent, trade
                        secret, trademark, URL, domain, invention or other intellectual property rights of Ours.
                    </p>
                    <p className='para-1'>
                        You shall not remove, alter or obscure any copyright, trademark, service mark or other
                        proprietary rights notices incorporated in or accompanying the Platform.
                    </p>
                    <p className='para-1'>
                        You agree to abide by all applicable intellectual property laws, as well as any additional
                        restrictions contained in the Platform.
                    </p>
                    <p className='para-1'>
                        All present and future rights in and to any trade secrets, copyright, trademark or any other
                        proprietary material contained in the Platform shall continue to remain the sole property
                        of Ours, even after the discontinuation of your account for any reason whatsoever.
                    </p>
                    <li className='heading'>
                        COPYRIGHT POLICY
                    </li>
                    <p className='para-1'>
                        You cannot post, distribute, or reproduce in any way any copyrighted material, trademarks,
                        or other proprietary information without obtaining the prior written consent of the owner
                        of such proprietary rights. Without limiting the foregoing, if you believe that your work has
                        been copied and posted on the HOPE BUDDY APP in a way that constitutes copyright
                        infringement, please provide us with the following information:
                        <ul>
                            <li>
                                an electronic or physical signature of the person authorized to act on behalf of the
                                owner of the copyright interest;
                            </li>
                            <li>
                                a description of the copyrighted work that you claim has been infringed;
                            </li>
                            <li>
                                a description of where the material that you claim is infringing is located on the
                                website;
                            </li>
                            <li>
                                your address, telephone number, and email address;
                            </li>
                            <li>
                                a written statement by you that you have a good faith belief that the disputed use is
                                not authorized by the copyright owner, its agent, or the law;
                            </li>
                            <li>
                                a statement by you, made under penalty of perjury, that the above information in
                                your Notice is accurate and that you are the copyright owner or authorized to act on
                                the copyright owner's behalf.
                            </li>
                        </ul>
                    </p>
                    <p className='para-1'>
                        Notice of claims of copyright infringement can be sent to us in writing to the Mumbai
                        address located under the Help/Contact section on the Platform.
                    </p>
                    <li className='heading'>
                        TERMINATION AND/OR CANCELLATION
                    </li>
                    <p className='para-1'>
                        <ul className='none'>
                            <li>
                                18.1 We reserve the right to suspend or terminate a User’s access to the APP and the Services
                                with or without notice and to exercise any other remedy available under law, in cases
                                where,
                            </li>
                            <ul style={{ listStyleType: 'square' }}>
                                <li>
                                    Such User commits breaches of any terms and conditions of the Agreement;
                                </li>
                                <li>
                                    A third-party reports violation of any of its right as a result of your use of the
                                    Services;
                                </li>
                                <li>
                                    We are unable to verify or authenticate any information provide to us by a
                                    User’s;
                                </li>
                                <li>
                                    We have reasonable grounds for suspecting any illegal, fraudulent or abusive
                                    activity on part of such Users; or
                                </li>
                                <li>
                                    we believe in its sole discretion that User’s actions may cause legal liability or
                                    are contrary to our interest or interest of platform.
                                </li>
                            </ul>
                            <li>
                                18.2 The User may not continue to use the platform under the same account, a different
                                account or re-register under a new account once temporarily suspended, indefinitely
                                suspended or terminated. On termination of an account due to the reasons
                                mentioned herein, such User will lose access to data, messages, files and other
                                content kept on the platform by such User.
                            </li>
                            <li>
                                18.3 In the event provider is willing to withdraw its subscription then he/she shall be liable
                                to give 90 days of notice to the Company. Any abrupt discontinuation viz. not
                                renewing subscription or not responding to company or lack of serving notice of
                                discontinuation will result in forfeiture of Security Deposit.
                            </li>
                            <li>
                                18.4 Under any circumstance refund of security deposit shall be subject all compliances
                                under this agreement and shall be made after all deductions including but not limited
                                to penalties or any other dues under this presents.
                            </li>
                        </ul>
                    </p>
                    <li className='heading'>
                        LIMITATION OF LIABILITY
                    </li>
                    <p className='para-1'>
                        In no event shall the Platform be liable for any direct, indirect, punitive, incidental, special
                        or consequential damages or for any damages whatsoever including, without limitation,
                        damages for loss of use, data or profits, arising out of or in any way connected with the use
                        of performance of the services for interrupted communications, delay, lost data or lost
                        profits arising out of or in connection with this agreement, even if the Platform has been
                        advised of the possibility of damages, or inability to use the services or other related
                        services, the provision of or failure to provide services, or for any information, software,
                        products, services and related graphics obtained through the other websites or services, or
                        otherwise arising out of the use of the other websites or services, whether based on
                        contract, tort, negligence, strict liability or otherwise, even if the Platform or any of its
                        suppliers have been advised of the possibility of damages. We do not endorse in any way
                        any advertisers/contents of advertisers on our Platform.
                    </p>
                    <li className='heading'>
                        DISCLAIMER
                    </li>
                    <p className='para-1'>
                        The Disclaimer by Company is as such:
                        <ol>
                            <li>
                                We are not responsible for any incorrect or inaccurate Content/listing posted on the
                                platform regarding medicine and/or medicinal products including but not limited to
                                dietary supplements etc., have not been evaluated or approved by the Drug
                                Controller of India or any other statutory body. We are not responsible for any
                                problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email or
                                players on account of technical problems or traffic congestion on the Internet or at
                                any website or combination thereof, including injury or damage to users or to any
                                other person's computer related to or resulting and/or in connection with the
                                Platform.
                            </li>
                            <li>
                                We have made available this service as a matter of convenience. We expressly
                                disclaimer any claim or liability arising uploading of any obscene, vulgar or
                                pornographic images, photographs or a picture or altering or distorting the images
                                available under this service in an obscene, vulgar or pornographic manner. The Users
                                irrevocably agrees conduct and agrees to indemnify and hereby indemnified us from
                                any legal actions in relation to such acts. we reserves the right to terminate your rights
                                to use the Service immediately in case of you uploading of any obscene, vulgar or
                                pornographic images, photographs or pictures or altering or distorting the images
                                available under the service in an obscene, vulgar or pornographic manner,
                                Notwithstanding the penal provisions under the Indian cyber laws or any other allied
                                laws enacted by the government of India or any other statutory, legislative Or
                                regulatory authority authorised in this regard from time to time.
                            </li>
                            <li>
                                Under no circumstances we will be responsible for any loss or damage resulting from
                                anyone's use of the platform or the Service and/or any content posted on the
                                Platform or transmitted to User. You should not provide your sensitive financial
                                information (for example, your credit card, debit card, bank account information,
                                code, pin etc.), or wire or otherwise send money, to other Users.
                            </li>
                            <li>
                                No User should ever ask for any sensitive bank information from at any time.
                            </li>
                            <li>
                                The Platform and the service are provided "AS-IS BASIS" and we expressly disclaim
                                any warranty of fitness for a particular purpose or non-infringement. We cannot
                                guarantee and does not promise any specific results from use of the Platform. You
                                understand that the Company makes no guarantees, either express or implied,
                                regarding compatibility with individuals you meet through the Service.
                            </li>
                            <li>
                                All liability, whether civil or criminal arising out of any content, if any that is posted
                                on the Platform or primary platform will be of that Users/ Users/ third party who has
                                Posted such content, and the Company reserves its right to claim damages from such
                                Users/ third party that it may suffer as a result of such content Posted on the website.
                                The Platform does not claim ownership of Content you submit or make available for
                                inclusion on the service.
                            </li>
                            <li>
                                We shall not be held responsible for any interactions/ passing of information(s) etc.
                                between any Users via e-mail, chat or any other medium with another Users and we
                                have no obligation to monitor any such disputes arising between the Users and we
                                shall not be a party to such dispute/litigation etc.
                            </li>
                        </ol>
                    </p>
                    <li className='heading'>
                        INDEMNITY
                    </li>
                    <p className='para-1'>
                        You agree to indemnify and hold the Company , its subsidiaries, affiliates, officers, agents,
                        and other partners and employees, fully indemnified and harmless from any loss, liability,
                        claim, or demand, including reasonable attorney's fees, made by any third party due to or
                        arising out of your use of the Service in violation of this Agreement and/or arising from a
                        breach of these Terms of Use and/or any breach of your representations and warranties set
                        forth above and/or any fraudulent act on your part.
                    </p>
                    <li className='heading'>
                        SUGGESTIONS, COMPLAINTS AND DISPUTES
                    </li>
                    <p className='para-1'>
                        Suggestions and complaints are to be first addressed to the HOPE BUDDY Customer Support
                        Department at <a href='mailto:support@hopetheapp.com'>support@hopetheapp.com</a> .
                    </p>
                    <li className='heading'>
                        ELECTRONIC COMMUNICATIVE
                    </li>
                    <p className='para-1'>
                        When you visit www.hopetheapp.com or send emails to us, you are communicating with us
                        electronically. You consent to receive communications from us electronically. We will
                        communicate with you by email or by posting notices on the Platform. You agree that all
                        agreements, notices, disclosures and other communications that we provide to you
                        electronically satisfy any legal requirement that such communications be in writing.
                    </p>
                </ol>
            </p>
            <p className='para'>
                <span className='bold'>
                    By becoming a user of Platform and/or using the Services of the Platform, you
                    unconditionally and irrevocably confirm that you have read and understood the above
                    provisions and agree to abide by them. If you are not agreeing to any of the above terms
                    and conditions, please refrain yourself from registering on the Platform.
                    HOPE BUDDY is a trademark of SOLUTIZE HEALTHCARE PRIVATE LIMITED This Agreement
                    is an electronic document in terms of the Information Technology Act, 2000 and Rules
                    there under pertaining to electronic records as applicable and amended. This electronic
                    record is generated by a computer system and does not require any physical or digital
                    signatures.
                </span>
            </p>
        </div>
    )
}

export default HopeBuddyTermsAndConditions;