
import React from 'react';
import './ExcelTableStyle.css';

const ExcelTable = ({ columns, data, onRowClick, AlternativeData, searchQuery,statusValue }) => {
    const exclamationMark = `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
<g clip-path="url(#clip0_7123_5452)">
  <path d="M10.5 7V10.5M10.5 14H10.5088M19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
  <clipPath id="clip0_7123_5452">
    <rect width="21" height="21" fill="white"/>
  </clipPath>
</defs>
</svg>`;

    const eyeIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z" stroke="#414141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z" stroke="#414141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        return `${dateObject.getDate().toString().padStart(2, '0')}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getFullYear()}`;
    };

    return (
        <div className="custom-table-container">
            <table className="custom-table">
                <thead>
                    <tr>
                        {columns.map(column => (
                            <th key={column.accessor}>{column.Header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {(searchQuery?.length > 0 ? AlternativeData : []).concat(data).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{row.userId?.length > 0 ? row.userId : "-"}</td>
                            <td>{row.firstName + " " + row.lastName}</td>
                            <td>{row.mobileNumber?.length > 0 ? row.mobileNumber : "-"}</td>
                            <td>{row.uniqueNumber?.length > 0 ? row.uniqueNumber : "-"}</td>
                            <td>{row.registrationNumber?.length > 0 ? row.registrationNumber : "-"}</td>
                            <td>{row.aadharNumber?.length > 0 ? row.aadharNumber : "-"}</td>
                            <td>{row.createdOn?.length > 0 ? formatDate(row.createdOn) : "-"}</td>
                            <td>
                                <button style={{
                                    background: statusValue === 'Active' ? "#D6C5FF" :
                                        statusValue === 'Pending' ? "#FEF0AB" : statusValue === 'Inactive'
                                            ? "#BABAC3" : statusValue === 'Rejected' ? "#EBA8A3" : statusValue === 'Blocked' ? "#FFD0AF" : null
                                }} className="custom-button">{statusValue}</button>
                            </td>
                            <td>
                                <button className='eyeIcon' onClick={() => onRowClick(row)}>
                                    <span dangerouslySetInnerHTML={{ __html: eyeIcon }} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
};

export default ExcelTable;
